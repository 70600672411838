import tenantApi from '@api';
import tenantRoutes from '@routes';
import tenantUtils from '@utils';
import tenantFilters from '@filters';
import { Radio, Typography } from 'antd';
import React, { useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  ConfirmationModal,
  DataTable,
  Group,
  Select,
  Spinner,
  Tag,
  TextInput,
  notification,
} from '../../../../../components/common';
import ListingDrawer from '../../../../../components/listing-drawer/listingDrawer';
import TruCheckModal from '../../../../../components/trucheckmodal/trucheckModal';
import TableActions from '../../../../../components/table/table-actions/table-actions';
import { strings } from '../../../../../constants/strings';
import { useGetLocation, useRouteNavigate } from '../../../../../hooks';
import { setListingsTableData, updateListForDashboard } from '../../../../../redux/dashboard/actionCreators';
import { fetchDeleteReasons, fetchListings, updateList } from '../../../../../redux/listings/actionCreator';
import { mapQueryStringToFilterObject } from '../../../../../utility/urlQuery';
import { deleteListingConfirmEvent } from '../../../../../services/analyticsService';
const { Title } = Typography;

const columns = [
  {
    title: 'ID',
    dataIndex: 'property_id',
    key: 'id',
    component: 'String',
  },
  {
    title: 'Listed Date',
    dataIndex: 'posted_on',
    key: 'posted_on',
    component: 'String',
  },
  {
    title: 'Purpose',
    dataIndex: 'purpose',
    key: 'purpose',
    component: 'String',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (props) => {
      return (
        <Tag color={props?.color} shape="round">
          {props?.label}
        </Tag>
      );
    },
  },
];

const ListingsRowActions = (props) => {
  const { t } = useTranslation();
  const { item, property_id, purpose, data } = props || {};
  const listingDrawerRef = useRef();

  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.loginUser.user);
  const hideListingRef = useRef();
  const unHideListingRef = useRef();
  const deleteListingRef = useRef();
  const changeOwnerRef = useRef();
  const trucheckModalRef = useRef();
  const navigate = useRouteNavigate();
  const location = useGetLocation();
  const path = location.pathname;
  const dispatch = useDispatch();
  const {
    list: deleteReasons,
    loading: deleteLoading,
    error: deleteError,
  } = useSelector((state) => state.listings.deleteReasons);
  const { isMobile, locale } = useSelector((state) => state.AppConfig);
  const users = useSelector((state) => state.userGroup.list);
  const { queryObj } = mapQueryStringToFilterObject(location.search);
  const filtersList = useMemo(() => tenantFilters.getMyListingsFilters(user, users, locale), [user, users, locale]);

  const [selectedReason, setSelectedReason] = useState(null);
  const [otherReason, setOtherReason] = useState('');
  const [deleteReasonError, setDeleteReasonError] = useState(null);
  const purposeId = item?.purposeId;
  const purposeSlug = item?.purpose;
  const handleReasonChange = (e) => {
    setSelectedReason(deleteReasons.find((reason) => reason.id == e.target.value));
    setDeleteReasonError(null);
  };

  const handleOtherReasonChange = (e) => {
    setOtherReason(e.target.value);
  };

  const refsObject = {
    showHideListingModal: () => hideListingRef?.current && hideListingRef?.current?.showModal(),
    showUnhideListingModal: () => unHideListingRef?.current && unHideListingRef?.current?.showModal(),
    showDeleteListingModal: () => {
      deleteListingRef?.current && deleteListingRef?.current?.showModal();
      dispatch(fetchDeleteReasons(purposeId));
    },
    showChangeListingOwnerModal: () => changeOwnerRef?.current && changeOwnerRef?.current?.showModal(),
    showEditListingPage: () => navigate(`${tenantRoutes.app().post_listing.path}/${property_id}`),
    showListingOnClassified: () => window.open(item?.public_url),
    showListingDetail: () => {
      listingDrawerRef?.current && listingDrawerRef?.current?.open(item?.id);
    },
    trucheckModal: () => {
      trucheckModalRef.current.show(item);
    },
  };

  const actionList = item?.listingRowActions(refsObject, loading);

  const onChangeOwner = () => {
    changeOwnerRef?.current && changeOwnerRef.current.hideModal();
  };

  const onHide = async () => {
    setLoading(true);
    hideListingRef?.current && hideListingRef.current.setLoading(true);
    unHideListingRef?.current && unHideListingRef.current.setLoading(true);
    const response = await tenantApi.hideUnhideListings(
      item?.listingOwner ? item?.listingOwner?.id : user?.id,
      property_id,
      data[0].slug,
      item?.status?.slug,
    );
    if (response) {
      setLoading(false);
      hideListingRef?.current && hideListingRef.current.hideModal();
      unHideListingRef?.current && unHideListingRef.current.hideModal();
      hideListingRef?.current && hideListingRef.current.setLoading(false);
      unHideListingRef?.current && unHideListingRef.current.setLoading(false);
      if (response.error) {
        notification.error(response.error);
      } else {
        if (response?.data && !response?.dontUpdate) {
          path === '/dashboard'
            ? dispatch(updateListForDashboard(response.data.data))
            : dispatch(updateList(response.data.data));
        }
        notification.success(
          item?.status?.label === 'Hidden' || item?.status?.slug === 'inactive'
            ? t('Listing has been re-published successfully!')
            : t('Listing has been hidden successfully!'),
        );
      }
    }
  };
  const onDelete = async () => {
    if (!selectedReason) {
      setDeleteReasonError(t('Please add reason to delete your listing.'));
      return;
    }
    if (selectedReason?.is_text_required && !otherReason.trim()) {
      setDeleteReasonError(t('Please add reason to delete your listing.'));
      return;
    }
    const payload = {
      reason_id: selectedReason?.id,
      reason_text: selectedReason?.slug === 'other' ? otherReason : selectedReason.name,
    };
    setLoading(true);
    !!deleteListingRef?.current && deleteListingRef.current.setLoading(true);
    const response = await tenantApi.deleteListings(
      item?.listingOwner ? item?.listingOwner?.id : user?.id,
      item?.id,
      payload.reason_id,
      payload.reason_text,
    );
    const query = new URLSearchParams(location.search);
    const statusId = query.get('q[status_id_eq]');
    if (response) {
      deleteListingConfirmEvent(
        user,
        statusId,
        response,
        selectedReason?.slug === 'other' ? otherReason : selectedReason?.name,
      );
      setLoading(false);
      !!deleteListingRef?.current && deleteListingRef.current.hideModal();
      !!deleteListingRef?.current && deleteListingRef.current.setLoading(false);
      if (response.error) {
        notification.error(response.error);
      } else {
        if (!!response?.data) {
          if (window.location.pathname.includes('/dashboard')) {
            dispatch(setListingsTableData(user.id, undefined, true, isMobile));
          } else {
            dispatch(fetchListings(user.id, queryObj, filtersList, isMobile ? '' : 'desktop', false));
          }
        }
        notification.success(t('Listing has been deleted!'));
        deleteListingRef?.current && deleteListingRef.current.hideModal();
        setDeleteReasonError(null);
      }
    }
  };

  const onCancelDeleteModal = () => {
    setDeleteReasonError(null);
    setSelectedReason(null);
    setOtherReason('');
  };

  const renderDataTable = (label, tableClassName) => {
    return (
      <>
        <div className="mb-8">{label}</div>
        <DataTable className={tableClassName} columns={columns} data={data} />
      </>
    );
  };

  return (
    <>
      {user?.is_admin && (
        <ConfirmationModal
          ref={changeOwnerRef}
          onSuccess={onChangeOwner}
          title={t('Change Listing Ownership')}
          okText={t('Change Owner')}
        >
          {renderDataTable(strings.msg_change_ownership, 'mb-16')}
          <Select label={strings.listing_owner} />
        </ConfirmationModal>
      )}
      <ConfirmationModal
        ref={hideListingRef}
        onSuccess={onHide}
        title={data?.[0]?.slug === 'zameen' ? t('Hide Listing') : t('Disable Listing')}
        type="danger"
        okText={data?.[0]?.slug === 'zameen' ? t('Hide Listing') : t('Disable')}
      >
        {renderDataTable(
          data?.[0]?.slug === 'zameen'
            ? t("The following listing will be hidden and won't be visible to users")
            : t("The following listing will be disabled and won't be visible to users"),
        )}
      </ConfirmationModal>
      <ConfirmationModal
        ref={unHideListingRef}
        onSuccess={onHide}
        title={data?.[0]?.slug === 'zameen' ? t('Unhide Listing') : t('Enable Listing')}
        type="danger"
        okText={data?.[0]?.slug === 'zameen' ? t('Unhide Listing') : t('Enable')}
      >
        {renderDataTable(
          data?.[0]?.slug === 'zameen'
            ? t('The following listing will be published And will be visible to user')
            : t('The following listing will be enabled and will be visible to user'),
        )}
      </ConfirmationModal>
      <ConfirmationModal
        ref={deleteListingRef}
        onSuccess={onDelete}
        title={t('Delete Listing')}
        type="danger"
        okText={t('Delete')}
        onCancel={onCancelDeleteModal}
        loading={deleteLoading || loading}
        bodyStyle={{ padding: isMobile ? 16 : 24 }}
        footerError={deleteReasonError && selectedReason?.slug != 'other' && deleteReasonError}
      >
        <Title className="mb-16" level={5}>
          {t('Why are you deleting your listing?')}
        </Title>

        <Radio.Group
          className="d-flex flex-column mb-8"
          style={{ gap: '16px' }}
          onChange={handleReasonChange}
          value={selectedReason?.id}
        >
          {deleteLoading ? (
            <Spinner />
          ) : (
            deleteReasons.map((option) => (
              <Radio className="color-gray-dark" value={option.id}>
                {tenantUtils.getLocalisedString(option, 'name')}
              </Radio>
            ))
          )}
        </Radio.Group>

        {selectedReason?.slug == 'other' && (
          <TextInput
            value={otherReason}
            handleChange={handleOtherReasonChange}
            errorMsg={deleteReasonError}
            lineCount={3}
            placeholder={t('Please enter your reason')}
          />
        )}
      </ConfirmationModal>
      <ListingDrawer ref={listingDrawerRef} />
      <TruCheckModal ref={trucheckModalRef} />
      <TableActions id={property_id} isMobile={props.isMobile} actionsList={actionList} wrapAction={true} />
    </>
  );
};

const RowActions = (props) => {
  const { t } = useTranslation();
  const { property_id, purpose, data = [] } = props || {};
  return (
    <Group template="auto" gap="8px" key={property_id} style={{ alignSelf: 'start' }}>
      {data &&
        data.length > 0 &&
        data?.map((item) => {
          return item.posted ? (
            <ListingsRowActions
              item={item}
              data={[{ ...item, purpose: t(purpose?.title) }]}
              property_id={property_id}
              platform={{ slug: item.slug, icon: item.icon }}
              key={item?.slug}
              isMobile={props.isMobile}
            />
          ) : (
            <div style={{ minHeight: 32 }} key={item?.slug}></div>
          );
        })}
    </Group>
  );
};

export default RowActions;
