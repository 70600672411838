import { useEffect } from 'react';

export const useScrollToPageSection = (sectionId, scrollBehavior = { behavior: 'smooth', block: 'center' }) => {
  useEffect(() => {
    const sectionElement = document.querySelector(window.location.hash || sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView(scrollBehavior);
    }
  }, [window.location.hash, sectionId, scrollBehavior]);
};
