import { NetworkService } from '../../../../services/networkService';

const getPaymentServiceCharges = async () => {
  const response = await NetworkService.get(`/api/checkout/service_charges`);
  console.log('===res', response);
  if (response?.error) {
    return response;
  }
  return { charges: response?.data?.channels_fee_percentage };
};

export default getPaymentServiceCharges;
