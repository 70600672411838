// import { BAYUT_KEY } from '../configs/bayut';
// import { ZAMEEN_KEY } from '../configs/zameen';
import ZameenComponents from './zameen';
import BayutComponents from './bayut';
import { checkRequiredKeys } from '../checkRequiredKeys';
import { TENANT_KEY } from '../../utility/env';

const components = [
  'HeaderComponent',
  'PostListingPage',
  'PlatformActions',
  'ListingsRowActions',
  'LocationSelect',
  'Health',
  'ListingsPage',
  'AgencyUserRowActions',
  'ListingsSummary',
  'Logo',
];

const TenantComponent = {
  ['zameen']: checkRequiredKeys(ZameenComponents, components),
  ['bayut']: checkRequiredKeys(BayutComponents, components),
};

export default TenantComponent[TENANT_KEY] || {};
