import tenantApi from '@api';
import { getErrorString } from '../../utility/utility';
import { setMemberArea, setMultiPlatform } from '../themeLayout/actionCreator';
import { setUsersList } from '../users/actionCreator';
import actions from './actions';

const {
  fetchUserSuccess,
  fetchUserBegin,
  fetchUserErr,
  fetchUserPermissionsSuccess,
  updateUserSetting,
  updateUserProducts,
  setUserSettings,
  setUserLoading,
  setUserAgencySettings,
  setUserNewAvailableCredis,
  setUserNewAvailableQuota,
  setUpdateUserData,
  setContractsDetails,
  setUpdateUserAgencyDetails,
} = actions;

const setCurrentUser = (user) => {
  return async (dispatch) => {
    dispatch(fetchUserSuccess(user));
  };
};

const fetchUserDetail = () => {
  return async (dispatch) => {
    try {
      // dispatch(fetchUserBegin());
      const response = await tenantApi.fetchUserDetail();
      if (response.error) {
        dispatch(fetchUserErr(response.error));
      } else if (response?.data) {
        const isMemberArea = response?.data?.is_package_user != undefined ? !response?.data?.is_package_user : false;
        const isAgencyAdmin = response?.data?.agency?.id && !!response?.data?.is_agency_admin;
        dispatch(setMemberArea(isMemberArea));
        dispatch(setMultiPlatform(!!response?.data?.is_multi_platform));
        isAgencyAdmin && dispatch(setUsersList(response?.data?.agency?.id));
        dispatch(fetchUserSuccess(response.data));
      }
    } catch (err) {
      dispatch(fetchUserErr(getErrorString(err)));
    }
  };
};

const fetchUserDetailFromToken = (token, onSuccess = async () => {}) => {
  return async (dispatch) => {
    try {
      dispatch(fetchUserBegin());
      const response = await tenantApi.fetchUserDetailFromToken(token);
      if (response.error) {
        dispatch(fetchUserErr(response.error));
      } else {
        if (response?.data) {
          const isMemberArea = response?.data?.is_package_user != undefined ? !response?.data?.is_package_user : false;
          const isAgencyAdmin = response?.data?.agency?.id && !!response?.data?.is_agency_admin;
          onSuccess(response?.data, isMemberArea, isAgencyAdmin).then(() => {
            dispatch(setMemberArea(isMemberArea));
            dispatch(setMultiPlatform(!!response?.data?.is_multi_platform));
            isAgencyAdmin && dispatch(setUsersList(response?.data?.agency?.id));
            dispatch(fetchUserSuccess(response.data));
          });
        }
      }
    } catch (err) {
      dispatch(fetchUserErr(getErrorString(err)));
    }
  };
};

const fetchContractsDetails = () => {
  return async (dispatch) => {
    try {
      const response = await tenantApi.fetchContractsDetails();
      if (!response?.error) {
        dispatch(setContractsDetails(response));
      }
    } catch (err) {}
  };
};

const fetchUserProducts = () => {
  return async (dispatch) => {
    try {
      const response = await tenantApi.fetchUserProductsDetail();
      if (response.data.products) {
        dispatch(updateUserProducts(response.data));
      }
    } catch (err) {}
  };
};
const fetchUserLoading = (data) => {
  return async (dispatch) => {
    dispatch(setUserLoading(data));
  };
};

const fetchUserSettings = (data) => {
  return async (dispatch) => {
    dispatch(setUserSettings(data));
  };
};

const setUserAgencySettingsValues = (data) => {
  return async (dispatch) => {
    dispatch(setUserAgencySettings(data));
  };
};

const setNewAvailableCredits = (data) => {
  return async (dispatch) => {
    dispatch(setUserNewAvailableCredis(data));
  };
};

const setNewAvailableQuota = (data) => {
  return async (dispatch) => {
    dispatch(setUserNewAvailableQuota(data));
  };
};

const updateUserData = (data) => {
  return async (dispatch) => {
    dispatch(setUpdateUserData(data));
  };
};

export {
  fetchContractsDetails,
  fetchUserDetail,
  fetchUserDetailFromToken,
  fetchUserLoading,
  fetchUserProducts,
  fetchUserSettings,
  setCurrentUser,
  setNewAvailableCredits,
  setNewAvailableQuota,
  setUserAgencySettingsValues,
  updateUserData,
  updateUserSetting,
  setUpdateUserAgencyDetails,
};
