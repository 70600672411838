import tenantApi from '@api';
import actions from './actions';

const {
  cartDataBegin,
  cartDataSuccess,
  cartDataErr,

  activeProductsBegin,
  activeProductsSuccess,
  activeProductsErr,

  cartUpdateBegin,
  cartUpdateSuccess,
  cartUpdateErr,

  cartDeleteBegin,
  cartDeleteSuccess,
  cartDeleteErr,

  cartEmpty,
} = actions;

const cartGetData = (userId, orderId) => {
  return async (dispatch) => {
    dispatch(cartDataBegin());
    const cartDetail = await tenantApi.cartDetail(userId, orderId);
    if (cartDetail?.error) {
      dispatch(cartDataErr(cartDetail.error));
    } else {
      dispatch(cartDataSuccess(cartDetail));
      // if (tenantApi?.getPaymentServiceCharges) {
      //   const res = await tenantApi.getPaymentServiceCharges();
      //   if (!res?.error && res?.charges) {
      //     dispatch(cartDataSuccess({ paymentServiceCharges: res?.charges }));
      //   }
      // }
    }
  };
};

const activeProducts = () => {
  return async (dispatch) => {
    dispatch(activeProductsBegin());
    const response = await tenantApi.activeProducts();
    if (response?.error) {
      dispatch(activeProductsErr(response.error));
    } else {
      dispatch(activeProductsSuccess(response));
    }
  };
};

const cartUpdateQuantity = (id, quantity, quantityObj) => {
  return async (dispatch) => {
    const newQuantityObj = { ...quantityObj, [id]: quantity };
    dispatch(cartUpdateSuccess(newQuantityObj));
  };
};

const cartDelete = (response) => {
  return async (dispatch) => {
    dispatch(cartDeleteSuccess(response.cartProducts));
  };
};

const updateCart = (responseData) => {
  return async (dispatch) => {
    dispatch(cartUpdateSuccess(responseData));
  };
};

const removeCart = () => {
  return async (dispatch) => {
    dispatch(cartEmpty());
  };
};

export { activeProducts, cartDelete, cartGetData, cartUpdateQuantity, removeCart, updateCart };
