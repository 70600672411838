import tenantConstants from '@constants';
import tenantData from '@data';
import tenantTheme from '@theme';
import tenantUtils from '@utils';
import { Badge, Divider, Space, Typography } from 'antd';
import { t } from 'i18next';
import React, { useRef } from 'react';
import TenantComponents from '../../../tenant/components';
import { getTimeDateString } from '../../../utility/date';
import { getBaseURL } from '../../../utility/env';
import { Flex, Icon, Image, Number, Popover, ProductTag, Tag, TextWithIcon } from '../../common';
import Group from '../../common/group/group';
import ListingDrawer from '../../listing-drawer/listingDrawer';
import { Thumbnail } from '../../styled';
import { useSelector } from 'react-redux';

const { Text } = Typography;

export const ListingPurpose = (props) => {
  const { productsInfo = {} } = props;
  const listingDrawerRef = useRef();
  const listingAreaUnit = tenantData.areaUnitList?.find((e) => e.title == props?.area?.unit);
  const rtl = useSelector((state) => state.AppConfig.rtl);

  const handleClick = () => {
    listingDrawerRef?.current && listingDrawerRef.current.open(props?.id);
  };
  return (
    <Group template="106px 1fr" gap=".571rem">
      <Badge
        count={props?.imageCount}
        size="default"
        overflowCount={90}
        offset={!rtl && [-105, 0]}
        style={{ backgroundColor: '#00a651' }}
        shape="square"
      >
        <Thumbnail>
          <Image
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
            src={props?.image?.thumbnail}
            fallback={`${getBaseURL()}/profolio-assets/images/ph-listings.svg`}
          />
        </Thumbnail>
      </Badge>

      <Group className="fz-12 py-2" template="initial" gap="4px">
        <Space className="fz-14 fw-800" style={{ lineHeight: 1.2 }}>
          <Number className="text-primary" compact={false} type="price" {...props?.price} />
          {props?.isBadge && (
            <>
              <ProductTag
                {...tenantData.getTagProps(productsInfo)}
                iconProps={{ ...tenantData.getTagProps(productsInfo).iconProps, size: '1.2em' }}
              />
            </>
          )}
          {props.health && <TenantComponents.Health {...props.health} listingsData={props} />}
        </Space>
        <span>
          {props?.type?.title && props?.purpose?.title && (
            <Tag
              color={tenantTheme['primary-light-4']}
              style={{ '--tag-color': tenantTheme['primary-color'], fontWeight: '700' }}
            >{`${tenantUtils.getLocalisedString(props?.type, 'title')} ${t('for ' + props?.purpose?.title)} `}</Tag>
          )}
        </span>
        <Space wrap={true} gap="10px">
          {props?.details?.listingSpecs
            ?.filter((data) => data?.value > 0)
            ?.map((e) => (
              <TextWithIcon icon={e?.icon} iconProps={e?.iconProps} textColor={e?.textColor} value={e?.value} />
            ))}
          <TextWithIcon
            icon="IconAreaSize"
            iconProps={{ size: '1.4em', color: tenantTheme.gray500 }}
            textColor={tenantTheme.gray700}
            value={`${props?.area?.value} ${t(listingAreaUnit?.title_short)}`}
          />
        </Space>
        <div>
          <div style={{ maxWidth: '30ch' }}>{props?.location?.breadcrumb}</div>
        </div>
        <span className="fz-10">
          <Text className="text-muted fz-10">
            {tenantConstants.SHOW_REGA_DETAIL ? t('Bayut ID:') : t('Listing ID:')}
          </Text>{' '}
          <Text className="text-primary fz-10">{props?.id}</Text>
          {tenantConstants.SHOW_REGA_DETAIL && (
            <>
              {props?.details?.regaId ? (
                <>
                  <Divider type="vertical" />
                  <Flex wrap={false} gap="2px" style={{ display: 'inline-flex' }}>
                    <Text className="text-muted fz-10">{t('REGA ID:')} </Text>
                    <Text className="text-primary inline-flex align-items-center fz-10" style={{ gap: 2 }}>
                      {props?.details?.regaId}
                      {props?.details?.regaExpiryDate && (
                        <Popover
                          content={
                            <>
                              {t('Expiring on')}: <strong>{getTimeDateString(props?.details?.regaExpiryDate)}</strong>
                            </>
                          }
                          action="hover"
                        >
                          <>
                            <Icon icon="AiOutlineInfoCircle" color={tenantTheme.gray700} />
                          </>
                        </Popover>
                      )}
                    </Text>
                  </Flex>
                </>
              ) : (
                <>
                  <Divider type="vertical" />
                  <Tag shape="round" style={{ alignSelf: 'center', fontSize: 12, lineHeight: 'normal' }}>
                    {t('Unlicensed')}
                  </Tag>
                </>
              )}
            </>
          )}
        </span>
      </Group>
      <ListingDrawer ref={listingDrawerRef} />
    </Group>
  );
};
