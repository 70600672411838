import tenantApi from '@api';
import { getErrorString } from '../../utility/utility';
import { setUpdateUserAgencyDetails } from '../user/actionCreator';

const setUsersList = (agencyId) => async (dispatch) => {
  const response = await tenantApi.fetchAgencyUsers(agencyId);
  if (response) {
    if (response.error) {
      dispatch({ type: 'SET_USERS_LIST', payload: { error: getErrorString(response.error) } });
    } else {
      dispatch(setUpdateUserAgencyDetails(response?.agencyDetails));
      dispatch({ type: 'SET_USERS_LIST', payload: { list: response?.agencyUsers, error: null } });
    }
  }
};

export { setUsersList };
