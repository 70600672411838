import tenantTheme from '@theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Alert, Button } from '../../../../components/common';
import { getClassifiedBaseURL } from '../../../../utility/env';

const PageAlert = () => {
  const { t } = useTranslation();
  const { user: loggedInUser } = useSelector((state) => state.loginUser);
  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  const locale = useSelector((state) => state.AppConfig.locale);

  return (
    <>
      {loggedInUser && loggedInUser?.is_nafaz_verified === false && (
        <Alert
          type="info"
          showIcon
          className="mb-20"
          style={{ zIndex: '100', margin: `${isMobile && '12px 1rem'}` }}
          message={t('Ready to post your listing? Complete your Nafath verification to kick off the posting process!')}
          action={
            <Button
              type={'link'}
              href={`${getClassifiedBaseURL()}/${locale}/verification/nafath/?redirectPath=${window.location.href}`}
              style={{
                '--btn-content-color': tenantTheme['base-color'],
                textDecoration: 'underline',
                margin: 0,
                '--btn-padding-y': 0,
              }}
              // onClick={nafathButtonClickEvent(loggedInUser, response, errorValue)}
            >
              {t('Verify')}
            </Button>
          }
          isMobile={isMobile}
        />
      )}
    </>
  );
};
export default PageAlert;
