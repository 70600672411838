import tenantUtils from '@utils';
import tenantData from '@data';
import { t } from 'i18next';
import { NetworkService } from '../../../../services/networkService';

const cartDetail = async (userId, orderId) => {
  const response = await (orderId
    ? NetworkService.get(`/api/users/${userId}/orders?order_id=${orderId}`)
    : NetworkService.get(`/api/carts/current`));

  if (response) {
    if (response.error) {
      return response;
    } else {
      if (orderId) {
        const { id, status, details } = response.data?.data?.orders?.items[0];
        if (status === 'completed') {
          return { error: `Order is in ${status} state` };
        }
        return {
          cartProducts: tenantUtils.cartProductList(
            details.map((e) => ({
              id: e.id,
              product_id: e.product.id,
              product: { id: e.product.id, title: e.product.name, slug: e.product_slug, price: e.unit_price },
              quantity: e.quantity,
              price: e.unit_price,
              source: 'profolio',
            })),
          ),
          cartId: id,
          source: 'profolio',
        };
      } else if (!response.data?.cart?.cart_details?.length) {
        const {
          id,
          source,
          amount,
          total_amount,
          total_credits,
          user_available_credits,
          listing_id,
          purchased_full_credits,
          discount,
          available_channels = [],
        } = response.data?.cart;

        return {
          cartId: id,
          source: source,
          total: amount,
          oldTotal: total_amount,
          totalCredits: total_credits,
          availableCredits: user_available_credits,
          listing_id,
          purchasedFullCredits: purchased_full_credits,
          cartProducts: [{ title: t('Credits'), quantity: total_credits, price: total_amount }],
          discount: discount,
          available_channels,
        };
      } else {
        const {
          id,
          source,
          cart_details,
          amount,
          total_amount,
          total_credits,
          user_available_credits,
          listing_id,
          purchased_full_credits,
          refundable_amount,
          discount,
          available_channels = [],
        } = response.data?.cart;
        return {
          cartProducts: tenantUtils.cartProductList(cart_details),
          cartId: id,
          source: source,
          total: amount,
          adjustment_amount: refundable_amount,
          discount: discount,
          oldTotal: total_amount,
          totalCredits: total_credits,
          availableCredits: user_available_credits,
          listing_id,
          purchasedFullCredits: purchased_full_credits,
          available_channels,
        };
      }
    }
  }
};

export const activeProducts = async (headers) => {
  const response = await NetworkService.get(`/api/products`, null, headers);
  const productsByPlatform = { ksa: [] };
  if (response) {
    if (response.error) {
      return response;
    } else if (!response?.data?.products?.length) {
      return {
        platformProducts: productsByPlatform,
        products: [],
      };
    } else {
      const productList = response?.data?.products;
      productList?.forEach((product) => {
        const { product_id, slug } = product;
        productsByPlatform.ksa.push({
          ...tenantData.getListingActions(slug),
          ...product,
          product_id,
          price: product?.product_price,
        });
      });
      return {
        platformProducts: productsByPlatform,
        products: productList.map((e) => ({ ...tenantData.getListingActions(e?.slug), ...e })),
      };
    }
  }
};

export default cartDetail;
