import tenantConstants from '@constants';
import { t } from 'i18next';
import * as yup from 'yup';
import { strings } from '../../../../constants/strings';
import { listingTypeIds, skipFieldsForField } from './listing-form-data';
const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
// const englishRegex = /^[A-Za-z0-9\s.,|`~\\"/'\[\]{}<>:?!@#$%^&*()_+=;-]*$/;
const englishRegex = /^[^\u0600-\u06FF]+$/;
// const arabicRegex = /^(?![-\s])[\u0600-\u06FF()0-9\s.,\-:|"'";@#$%^&*+}{}?()=<>_!/]*[\u0600-\u06FF0-9\s.,\-:|"'";@#$%^&*+}{}?()=<>_!/]*$/;
const arabicRegex = /^[^A-Za-z]+$/;
export const getValidationSchema = (locale, purposeSlug, type_id, is_mobile_verified) => {
  const isSellOrRentProperty = purposeSlug === 'sale' || purposeSlug === 'rent';
  const isDailyRental = purposeSlug === 'daily-rental';
  return yup.object().shape({
    bedrooms: yup.mixed().when(['sub_listing_type','property_type'], ([sub_listing_type, property_type], schema) => {
      const shouldRequire = !(
        skipFieldsForField?.['bedrooms'].property_type?.[sub_listing_type?.id || type_id || property_type]?.skipField ||
        skipFieldsForField?.['bedrooms'].property_type?.[sub_listing_type?.id || type_id || property_type]?.isOptional
      );

      return shouldRequire
        ? yup.string().required(t('Please select number of bedrooms')).typeError(t('Please select number of bedrooms'))
        : schema.notRequired();
    }),
    property_type: yup.string().required(t('Please select property type')).typeError(t('Please select property type')),

    bathrooms: yup.mixed().when('sub_listing_type', (sub_listing_type, schema) => {
      const shouldRequire = !(
        skipFieldsForField?.['bathrooms'].property_type?.[sub_listing_type?.id || type_id]?.skipField ||
        skipFieldsForField?.['bathrooms'].property_type?.[sub_listing_type?.id || type_id]?.isOptional
      );

      return shouldRequire
        ? yup
            .string()
            .required(t('Please select number of bathrooms'))
            .typeError(t('Please select number of bathrooms'))
        : schema.notRequired();
    }),

      ...(!is_mobile_verified && {
      mobile: yup
        .string()
        .required(t('Please enter your number'))
        .test('format', t(strings.im.phone), value => {
          if (!value) return true;
          return tenantConstants.phoneRegex ? tenantConstants.phoneRegex.test(value) : true;
        })
        .nullable(),
      }),
    ...((isSellOrRentProperty) && {furnished: yup.mixed().when('sub_listing_type', (sub_listing_type, schema) => {
      const shouldRequire = !(
        skipFieldsForField?.['furnished'].property_type?.[sub_listing_type?.id || type_id]?.skipField ||
        skipFieldsForField?.['furnished'].property_type?.[sub_listing_type?.id || type_id]?.isOptional
      );

      return shouldRequire
        ? yup
            .string()
            .required(t('Please select a value for furnished'))
            .typeError(t('Please select a value for furnished'))
        : schema.notRequired();
    })}),

    ...((purposeSlug == 'sale' || isDailyRental) && {
      price: yup
        .number()
        .required(t('Please enter price'))
        .moreThan(0, t('Invalid Price'))
        .typeError(t('Invalid Price')),
    }),

    ...(isDailyRental && {
      area: yup
        .number()
        .required(t('Please enter area'))
        .typeError(t('Invalid Area'))
        .test(
        'is-integer-string',
        t('Invalid Area'),
        (val) => {
        const stringValue = val?.toString();
        return /^[0-9]+$/.test(stringValue);
       }
  ),
    }),

    ...(isDailyRental && {
       national_address: yup
       .string()
       .nullable() 
       .notRequired() 
       .test(
        'len',
        t('Please enter a valid National Address'),
        (value) => !value || value.length == 8 
        )
       .matches(
        /^[A-Z]{4}[0-9]{4}$/, 
        t('Please enter a valid National Address'),
       )
    }),

    ...(purposeSlug == 'rent' && {
      rental_frequency: yup
        .number()
        .required(t('Please select rental frequency'))
        .nullable(),
      rental_price: yup
        .number()
        .required(t('Please enter price'))
        .moreThan(0, t('Invalid Price'))
        .typeError(t('Invalid Price')),
    }),

    sub_listing_type: yup.mixed().when('property_type', (property_type, schema) => {
      const shouldRequire = property_type === listingTypeIds.Land || property_type === listingTypeIds.Building;

      return shouldRequire
        ? yup
            .object()
            .required(t('Please select property type'))
            .nullable()
        : schema.notRequired();
    }),

    property_title_en:
      locale == 'en'
        ? yup
            .string()
            .required(t('Please enter property title'))
            .nullable()
            .test('len', t('Minimum of 5 characters required'), val => val?.length >= 5)
            .test('is-english', t('Please enter your title in English only'), val => englishRegex.test(val))
        : yup.string().nullable(),

    property_title_ar:
      locale == 'ar'
        ? yup
            .string()
            .required(t('Please enter property title ar'))
            .nullable()
            .test('len', t('Minimum of 5 characters required'), val => val?.length >= 5)
            .test('is-arabic', t('يرجى إدخال عنوان الأعلان بالعربي'), val => arabicRegex.test(val))
        : yup.string().nullable(),

    property_description_ar:
      locale == 'ar'
        ? yup
            .string()
            .required(t('Please enter property description ar'))
            .nullable()
            .test('len', t('Minimum of 20 characters required'), val => val?.length >= 20)
            .test('is-arabic', t('يرجى إدخال وصف العقار بالعربي'), val => arabicRegex.test(val))
        : yup.string().nullable(),

    property_description_en:
      locale == 'en'
        ? yup
            .string()
            .required(t('Please enter property description'))
            .nullable()
            .test('len', t('Minimum of 20 characters required'), val => val?.length >= 20)
            .test('is-english', t('Please enter your description in English only'), val => englishRegex.test(val))
        : yup.string().nullable(),

    'location-info': yup
      .object({
        city: yup.object().required(t('City is required')),
      })
      .required(t('City is required')),
  });
};
