import tenantUtils from '@utils';
import tenantConstants from '@constants';
import store from '../../../../redux/store';
import { NetworkService } from '../../../../services/networkService';
import { getVariousDates } from '../../../../utility/date';
import { postedToBayut } from '../listings/listingsApis';
import tenantData from '@data';

const tableMapper = (e, stats, user) => {
  const listingStats = stats?.find((it) => it?.ad_external_id == e?.id);
  return {
    ...e,
    id: e.id,
    type: e?.listing_type?.title,
    location: e?.location?.title,
    property: {
      id: e?.id,
      ...(e?.listing_purpose && {
        purpose: { title: e?.listing_purpose?.title, title_l1: e?.listing_purpose?.title_l1 },
      }),
      type: { title: e?.listing_type?.title, title_l1: e?.listing_type?.title_l1 },
      area: { value: e?.area_unit?.value, unit: e?.area_unit?.name },
      location: {
        ...e?.location,
        breadcrumb: e?.location?.breadcrumb
          ?.reverse()
          ?.filter((e) => e?.level > 1)
          ?.map((e) => tenantUtils.getLocalisedString(e, 'title'))
          ?.join(', '),
      },
      image: e?.image,
      price: { value: e.price, currency: 'SAR' },
      details: {
        ...e?.rega_details?.property_specs,
        regaId: e?.ad_license,
        listingSpecs: tenantData?.getListingSpecs(e),
      },
      ...(e?.health && {
        health: e?.health,
      }),
      productsInfo: e?.platforms?.ksa?.products_information, //Pending
    },
    platforms: {
      property_id: e?.id,
      ...(e?.listing_purpose && { purpose: e.listing_purpose }),
      ...(e?.listing_type && { type: e.listing_type }),
      price: { value: e?.price, currency: e?.currency },
      location: e.location,
      stories: e?.stories,
      stats: stats?.find((it) => it?.ad_external_id == e?.id),
      data: [
        ...(!!user?.products?.platforms?.['ksa']
          ? [
              {
                id: e.id,
                property_id: e?.id,
                slug: 'ksa',
                posted_on: e?.created_at,
                expiry_date: e?.expiry_date,
                status: tenantUtils.listingStatusMapper(e?.platform?.zameen?.status),
                posted: postedToBayut(e?.platform?.['ksa']),
                views: listingStats?.sum_search_count,
                leads: listingStats?.sum_lead_count,
                clicks: listingStats?.sum_view_count,
                ctr: (listingStats?.sum_view_count / listingStats?.sum_search_count) * 100,
                calls: listingStats?.sum_phone_view_count,
                emails: listingStats?.sum_email_lead_count,
                sms: listingStats?.sum_sms_view_count,
                whatsapp: listingStats?.sum_whatsapp_view_count,
                chat: listingStats?.sum_chat_lead_count,
                category: listingStats?.platform?.['ksa']?.mark?.title,
                icon: 'IconKSA',
                public_url: listingStats?.platform?.['ksa']?.public_url,
              },
            ]
          : []),
      ],
    },
  };
};

function convertArrayToQueryString(arr, paramName) {
  if (!Array.isArray(arr) || arr.length === 0) {
    return '';
  }

  const queryString = arr.map((item) => `${paramName}[]=${item}`).join('&');
  return queryString;
}

export const fetchListingPeformanceData = async (userId, params) => {
  const { [`filter[id]`]: listingId, page = '' } = params;
  const user = store.getState().loginUser.user;

  const listings = await NetworkService.get(
    `/api/listings/my_listings?${listingId ? `q[listing_id_eq]=${listingId}` : ''}&${page ? `page=${page}` : ''}`,
  );

  if (listings?.error) {
    return listings;
  } else {
    if (listings?.data?.listing?.length) {
      const pagination = listings?.data?.pagination;

      const listingIds = listings?.data?.listing?.map((e) => e?.id);
      const userIds = listings?.data?.listing?.map((e) => (tenantConstants.KC_ENABLED ? e?.user?.external_id : e?.id));
      let filteredUserIds = [...new Set(userIds.filter((external_id) => !!external_id))];
      const date_between = getVariousDates(59);
      const params = `${convertArrayToQueryString([1, 2], 'category_ids')}&${convertArrayToQueryString(
        filteredUserIds,
        'user_external_ids',
      )}&${convertArrayToQueryString(['ad_external_id'], 'group_by')}&${convertArrayToQueryString(
        listingIds,
        'ad_external_ids',
      )}`;
      const stats = await NetworkService.get(
        `/api/ovation/stats?${params}&start_date=${date_between?.[0]}&end_date=${date_between?.[1]}`,
      );
      if (stats?.error) {
        return stats;
      } else {
        return {
          list: listings?.data?.listing?.map((e) => tableMapper(e, stats?.data?.stats?.items, user)),
          pagination: tenantUtils.getPaginationObject(pagination),
          table: [
            {
              title: 'Property',
              dataIndex: 'property',
              key: 'property',
              component: 'ListingPurpose',
              className: 'col-property',
            },

            {
              title: 'Posted On',
              dataIndex: 'platforms',
              key: 'posted_on',
              component: 'Date',
            },
            {
              title: 'Views',
              dataIndex: 'platforms',
              key: 'views',
              component: 'Stats',
            },
            {
              title: 'Clicks',
              dataIndex: 'platforms',
              key: 'clicks',
              component: 'Stats',
            },
            {
              title: 'All Leads',
              dataIndex: 'platforms',
              key: 'leads',
              component: 'Stats',
            },
            {
              title: 'Whatsapp',
              dataIndex: 'platforms',
              key: 'whatsapp',
              component: 'Stats',
            },
            {
              title: 'Calls',
              dataIndex: 'platforms',
              key: 'calls',
              component: 'Stats',
            },
            {
              title: 'SMS',
              dataIndex: 'platforms',
              key: 'sms',
              component: 'Stats',
            },
            {
              title: 'Emails',
              dataIndex: 'platforms',
              key: 'emails',
              component: 'Stats',
            },
          ],
        };
      }
    } else {
      return {
        list: [],
        pagination: tenantUtils.getPaginationObject({}),
      };
    }
  }
};
