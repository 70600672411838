import tenantRoutes from '@routes';
import tenantTheme from '@theme';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ActionPopOver, Button, Group, Icon } from '../../../../../components/common';
import RenderUpgradeIcons from '../../../../../components/common/upgrade-icons/upgrade-icons';
import { TIME_DATE_FORMAT } from '../../../../../constants/formats';
import { useApplyProductModalData, useRouteNavigate } from '../../../../../hooks';
import { getTimeDateString } from '../../../../../utility/date';
import { QuotaCreditModal } from './quotaCreditModal';

const PlatformActions = (props) => {
  const { t } = useTranslation();
  const { data = [], stories = {}, location, purpose, stats, ...rest } = props;
  const deductionModalRef = useRef();
  const { user } = useSelector((state) => state.loginUser);
  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  const serviceDataRef = useRef();
  const navigate = useRouteNavigate();

  const {
    confirmationLoading,
    selectedPaymentOption,
    paymentOptions,
    action,
    onSuccessQuotaCreditModal,
    getDeduction,
    onChangePaymentOption,
    onCancelModal,
    actionLoading,
    onApplyProduct,
  } = useApplyProductModalData(user, deductionModalRef, serviceDataRef, data);

  return (
    <Group className={rest.className} gap="8px" template="repeat(3, minmax(0, 32px))" style={{ ...rest.style }}>
      <QuotaCreditModal
        ref={deductionModalRef}
        title={action?.applicableProduct?.requestTitle}
        action={action}
        okText={action?.isSufficient ? 'Submit' : 'Continue'}
        onSuccess={onSuccessQuotaCreditModal}
        onCancel={onCancelModal}
        loading={confirmationLoading}
        serviceDataRef={serviceDataRef}
        selectedPaymentOption={selectedPaymentOption}
        paymentOptions={paymentOptions}
        onChangePaymentOption={onChangePaymentOption}
        showInsufficientCreditsAlert
        showInfoMessage
        hideDataTable
      />
      {data?.map((item) => {
        const { stories: listingStories = [] } = item;
        return (
          <>
            {!!item?.listingPlatformActions && !!item.listingPlatformActions?.length > 0 ? (
              item?.listingPlatformActions.map((e, index) => {
                const actionStyles =
                  !!e?.applied || !!e?.canApply || !!e?.pending
                    ? {
                        opaque: e.opaque,
                        color: e?.slug === 'property_videography' ? '#0793ea14' : e?.color || e?.iconProps?.color,
                        iconColor: e?.slug === 'property_videography' ? '#0793ea' : e.iconColor,
                      }
                    : !e?.canApply
                      ? { enableOnHover: false }
                      : {};

                return (
                  <>
                    {e?.type === 'icon' ? (
                      <>
                        <RenderUpgradeIcons
                          selectedProduct={e}
                          toolTipTitle={
                            !e?.pending && !e?.applied && !e?.canApply
                              ? e?.isAddOn
                                ? t('This service is not available in your region yet.')
                                : ''
                              : !isMobile && (
                                  <ActionPopOver
                                    title={
                                      e?.applied ? t(e?.appliedTitle) : e?.pending ? t(e?.pendingTitle) : t(e?.title)
                                    }
                                    appliedDescription={t(e?.applied) ? t(e?.appliedDescription) : undefined}
                                    expiryTime={getTimeDateString(e?.time_to_expiry, TIME_DATE_FORMAT)}
                                    requestedAt={
                                      !e?.applied ? getTimeDateString(e?.requested_at, TIME_DATE_FORMAT) : null
                                    }
                                    stories={listingStories}
                                    listing={item}
                                    stats={stats}
                                  />
                                )
                          }
                          deductionType={'credit'}
                          actionStyles={actionStyles}
                          listing={item}
                          loading={actionLoading?.[`${item?.slug}${e?.name}`]}
                          index={index}
                          disabled={(!e?.applied && !e?.canApply) || e?.applied}
                          onIconClick={getDeduction}
                        />
                      </>
                    ) : (
                      <div className="span-all">
                        <Button
                          key={item?.slug + index}
                          onClick={() => {
                            if (!user?.isCurrencyUser) {
                              onApplyProduct(e?.id, item?.id);
                            } else {
                              navigate(
                                `${tenantRoutes.app().post_listing.path}/${item?.id}${item?.location?.id ? '/upgrade' : ''}`,
                              );
                            }
                          }}
                          style={{
                            '--btn-content-color': tenantTheme['primary-color'],
                            '--btn-border-color': tenantTheme['primary-color'],
                            paddingInline: 12,
                          }}
                          {...actionStyles}
                          loading={actionLoading?.[`${item?.slug}${e?.name}`]}
                          type="primary-light"
                        >
                          {t(e?.title)}
                          {e?.icon && <Icon icon={e?.icon} size="1.4em" style={{ verticalAlign: 'text-bottom' }} />}
                        </Button>
                      </div>
                    )}
                  </>
                );
              })
            ) : (
              <div style={{ height: '32px' }} className="span-all" />
            )}
          </>
        );
      })}
    </Group>
  );
};

export default PlatformActions;
