import tenantConstants from '@constants';
import tenantTheme from '@theme';
import tenantUtils from '@utils';
import { Space, Typography } from 'antd';
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdArrowOutward } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Button, Card, Drawer, Flex, Group, Icon, Number, Tag, TextWithIcon } from '../common';
import QR_Code from '../common/qr-code/qr-code';
import ImageGallery from '../image-gallery/image-gallery';
import ListDetail from '../list-detail/list-detail';
import ListingDrawerSkeleton from './listingDrawerSkeleton';
const { Text, Title, Paragraph } = Typography;
import tenantApi from '@api';
import amenities from '../../components/post-listing/add-amenities/amenities.json';

const ListingDrawer = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedL1, setIsExpandedL1] = useState(false);
  const [listing, setListing] = useState(null);

  const listingDetailDrawerRef = useRef();
  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  const { rtl } = useSelector((state) => state?.AppConfig);
  const { user } = useSelector((state) => state.loginUser);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleDescriptionL1 = () => {
    setIsExpandedL1(!isExpandedL1);
  };

  const fetchListingDetail = async (id) => {
    if (id) {
      const response = await tenantApi.getListingDetail(id);
      if (response) {
        if (!response.error) {
          setListing(tenantApi.listingMapper(response, user, true));
          setLoading(false);
        }
      }
    }
  };

  useImperativeHandle(ref, () => ({
    open(id) {
      fetchListingDetail(id);
      listingDetailDrawerRef?.current && listingDetailDrawerRef?.current?.openDrawer();
    },
  }));

  const closeDrawer = () => {
    listingDetailDrawerRef?.current?.closeDrawer();
  };

  const description = listing?.description;
  const isLongDescription = description && description.length > 250;

  const descriptionL1 = listing?.description_l1;
  const isLongDescriptionL1 = descriptionL1 && descriptionL1.length > 250;

  const breadcrumb = useMemo(() => {
    return listing?.location?.breadcrumb
      ?.filter((e) => e?.level > 1)
      ?.map((e) => tenantUtils.getLocalisedString(e, 'title'))
      ?.join(', ');
  }, [listing]);

  const getCityFromBreadcrumb = (breadcrumb) => {
    const cityItem = breadcrumb?.find((item) => item.level === 2);
    return cityItem ? tenantUtils.getLocalisedString(cityItem, 'title') : '-';
  };

  const getLocationFromBreadcrumb = (breadcrumb) => {
    const locationItem = breadcrumb?.[0];
    return locationItem ? tenantUtils.getLocalisedString(locationItem, 'title') : '-';
  };

  const getIconName = ({ feature_id }) =>
    tenantConstants?.SHOW_AMENITIES_ICON
      ? amenities?.[feature_id]?.svg
        ? amenities[feature_id].svg
        : 'MdIndeterminateCheckBox'
      : '';

  const propertySpecs = useMemo(
    () => [
      { label: t('Property Type'), value: tenantUtils.getLocalisedString(listing?.listing_type, 'title') },
      listing?.hasOwnProperty('residence_type') && {
        label: t('Residence Type'),
        value: tenantUtils.getLocalisedString(listing?.residence_type, 'name'),
      },
      { label: t('City'), value: getCityFromBreadcrumb(listing?.location?.breadcrumb) },
      { label: t('Location'), value: getLocationFromBreadcrumb(listing?.location?.breadcrumb) },
      { label: t('Purpose'), value: tenantUtils.getLocalisedString(listing?.listing_purpose, 'title') },
      listing?.hasOwnProperty('is_furnished') && {
        label: t('Furnished'),
        value: listing?.is_furnished ? t('Yes') : t('No'),
      },
      { label: tenantConstants.SHOW_REGA_DETAIL ? t('Bayut ID') : t('Listing ID'), value: listing?.id },
    ],
    [t, listing],
  );

  return (
    <>
      <Drawer
        title={
          <div>
            <Title level={5} style={{ fontWeight: '700' }}>
              {tenantUtils.getLocalisedString(listing, 'title')}
            </Title>
            <Paragraph className="fz-12 fw-700 mb-0" style={{ color: tenantTheme['base-color'] }}>
              {!loading && (
                <Text type="secondary fw-400" style={{ marginInlineEnd: '4px' }}>
                  {t('Posted By')}
                  {': '}
                </Text>
              )}
              {tenantUtils.getLocalisedString(listing?.posted_by, 'name')}
            </Paragraph>
          </div>
        }
        placement={isMobile ? 'bottom' : rtl ? 'left' : 'right'}
        ref={listingDetailDrawerRef}
        onCloseDrawer={() => {}}
        closable={false}
        width="40vw"
        height={isMobile ? '100vh' : ''}
        footer={null}
        bodyStyle={null}
      >
        {!!loading ? (
          <ListingDrawerSkeleton />
        ) : (
          <div>
            <ImageGallery
              loading={loading}
              images={listing?.images}
              modalTitle={tenantUtils.getLocalisedString(listing, 'title')}
              className={isMobile ? 'mb-16' : 'mb-24'}
              imagecount={4}
            />
            <Group gap={isMobile ? '25px' : '35px'}>
              <div>
                <Text type="secondary">{t(listing?.price?.currency)} </Text>
                <Text className=" fw-800 color-primary">
                  <Number
                    className="fz-20"
                    value={listing?.price?.value ? listing?.price?.value : 0}
                    compact={false}
                    style={{ color: tenantTheme['primary-color'] }}
                  />{' '}
                  {listing?.rent_frequency && tenantUtils.getLocalisedString(listing, 'rent_frequency')}
                </Text>

                <Title level={5} className="mb-16 mt-0">
                  {breadcrumb}
                </Title>
                <Space size="large">
                  {listing?.beds > 0 && (
                    <TextWithIcon
                      icon="IconBedroom"
                      iconProps={{ size: '1.2em', color: tenantTheme['primary-color'] }}
                      value={`${listing?.beds} ${t('Beds')}`}
                    />
                  )}
                  {listing?.baths > 0 && (
                    <TextWithIcon
                      icon="IconBathroom"
                      iconProps={{ size: '1.2em', color: tenantTheme['primary-color'] }}
                      value={`${listing?.baths} ${t('Baths')}`}
                    />
                  )}
                  {listing?.area_unit?.value && (
                    <TextWithIcon
                      icon="IconAreaSize"
                      iconProps={{ size: '1.4em', color: tenantTheme['primary-color'] }}
                      value={
                        `${listing?.area_unit?.value}` +
                        ' ' +
                        tenantUtils.getLocalisedString(listing?.area_unit, 'short_name')
                      }
                    />
                  )}
                </Space>
              </div>

              <div>
                <div className="mb-12" style={{ textAlign: 'left' }}>
                  {listing?.title && (
                    <Title strong level={5} style={{ fontSize: '14px' }}>
                      {listing?.title}
                    </Title>
                  )}
                  {description && (
                    <Paragraph
                      className="color-gray-dark mb-8 fz-12"
                      ellipsis={isLongDescription && !isExpanded ? { rows: 3, expandable: false } : false}
                    >
                      {description}
                    </Paragraph>
                  )}
                  {isLongDescription && (
                    <Button
                      className="p-0"
                      type="link"
                      icon={isExpanded ? 'IoIosArrowUp' : 'IoIosArrowDown'}
                      onClick={toggleDescription}
                    >
                      {isExpanded ? 'Show Less' : 'Read More'}
                    </Button>
                  )}
                </div>
                <div className="dir-rtl">
                  {listing?.title_l1 && (
                    <Title strong level={5} style={{ fontSize: '14px' }}>
                      {listing?.title_l1}
                    </Title>
                  )}
                  {descriptionL1 && (
                    <Paragraph
                      className="color-gray-dark mb-8 fz-12"
                      ellipsis={{ rows: isExpandedL1 ? 0 : 3, expandable: false }}
                    >
                      {descriptionL1}
                    </Paragraph>
                  )}
                  {isLongDescriptionL1 && (
                    <Button
                      className="p-0"
                      type="link"
                      icon={isExpandedL1 ? 'IoIosArrowUp' : 'IoIosArrowDown'}
                      onClick={toggleDescriptionL1}
                    >
                      {isExpandedL1 ? 'اقرأ أقل' : 'اقرأ أكثر'}
                    </Button>
                  )}
                </div>
              </div>

              <ListDetail data={propertySpecs} title={t('Property Information')} />

              {tenantConstants.SHOW_REGA_DETAIL && !listing?.isDailyRental && (
                <div>
                  <Title level={5} style={{ fontWeight: '800' }}>
                    {t('REGA Information')}
                  </Title>
                  <Card style={{ backgroundColor: tenantTheme['primary-light-4'], borderWidth: 0 }}>
                    <Flex justify="space-between" align={isMobile && 'center'}>
                      <div className={!isMobile && 'py-24'}>
                        {listing?.rega_details?.license_info?.ad_license_number && (
                          <TextWithIcon
                            textSize={isMobile && '12px'}
                            icon={'PiSealCheckFill'}
                            iconProps={{ color: tenantTheme['primary-color'] }}
                            value={
                              <span className="color-gray-dark">
                                {t('REGA Ad License ID')} {':'}{' '}
                                <span style={{ color: tenantTheme['base-color'] }}>
                                  {listing?.rega_details?.license_info?.ad_license_number}
                                </span>
                              </span>
                            }
                          />
                        )}

                        {listing?.rega_details?.license_info?.fal_license_number && (
                          <TextWithIcon
                            icon={'FaAddressCard'}
                            iconProps={{ color: tenantTheme['primary-color'] }}
                            value={
                              <span className="color-gray-dark">
                                {t('FAL License no')} {':'}{' '}
                                <span style={{ color: tenantTheme['base-color'] }}>
                                  {listing?.rega_details?.license_info?.fal_license_number}
                                </span>
                              </span>
                            }
                          />
                        )}
                      </div>

                      <Flex gap="10px" justify="center" align="center">
                        <a
                          style={{ width: '10ch' }}
                          className="color-primary fz-12 fw-700"
                          href={listing?.rega_details?.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('View Details on REGA')}
                          <MdArrowOutward style={{ marginInlineStart: '4px' }} size={14} />
                        </a>
                        {listing?.rega_details?.url && (
                          <QR_Code size={50} value={listing?.rega_details?.url} viewBox={`0 0 256 256`} />
                        )}
                      </Flex>
                    </Flex>
                  </Card>
                </div>
              )}
              <div className="fs12">
                <Title level={5} style={{ fontWeight: '800' }}>
                  {t('Feature and Amenities')}
                </Title>

                {listing?.listing_features.length < 1 && (
                  <div className="">
                    <Paragraph ellipsis={{ rows: isExpanded ? 0 : 3, expandable: false }}>
                      {t('No features and amenities have been added')}
                    </Paragraph>
                  </div>
                )}
                <Flex vertical={isMobile && true} wrap>
                  {listing?.listing_features.map((group) => (
                    <div key={group.group_id} className="mb-16">
                      <Text className="mb-8 fz-14" style={{ display: 'block' }}>
                        {tenantUtils.getLocalisedString(group, 'title')}
                      </Text>
                      <div className="flex" style={{ gap: '8px', flexFlow: 'wrap' }}>
                        {group.features.map((feature) => (
                          <Tag
                            key={feature.id}
                            shape="round"
                            bordered
                            spaceTop="1px"
                            icon={<Icon icon={getIconName(feature)} style={{}} />}
                            style={{
                              overflow: 'hidden',
                              display: 'inline-flex',
                              '--space-top': '1px',
                              backgroundColor: tenantTheme.gray200,
                            }}
                          >
                            {tenantUtils.getLocalisedString(feature, 'title')}
                          </Tag>
                        ))}
                      </div>
                    </div>
                  ))}
                </Flex>
              </div>
            </Group>
          </div>
        )}
      </Drawer>
    </>
  );
});

export default ListingDrawer;
