import tenantUtils from '@utils';
import store from '../../../../redux/store';
import { NetworkService } from '../../../../services/networkService';
import { postedToOLX, postedToZameen } from '../listings/listingsApis';
import tenantData from '@data';

const tableMapper = (e, user) => {
  return {
    ...e,
    id: e.id,
    type: e?.listing_type?.title,
    location: e?.location?.title,
    property: {
      id: e?.id,
      ...(e?.listing_purpose && { purpose: { title: e?.listing_purpose?.title } }),
      type: { title: e?.listing_type?.title },
      area: { value: e?.area_unit_value, unit: e?.area_unit },
      location: {
        ...e?.location,
        breadcrumb: e?.location?.breadcrumb
          ?.filter((e) => e?.level > 1)
          ?.map((e) => tenantUtils.getLocalisedString(e, 'title'))
          ?.join(', '),
      },
      details: {
        listingSpecs: tenantData?.getListingSpecs(e),
      },
      image: e?.image,
      price: { value: e.price, currency: e?.currency },
      ...(e?.listing_health && {
        health: e?.listing_health,
      }),
    },
    platforms: {
      property_id: e?.id,
      ...(e?.listing_purpose && { purpose: e.listing_purpose }),
      ...(e?.listing_type && { type: e.listing_type }),
      price: { value: e?.price, currency: e?.currency },
      location: e.location,
      stories: e?.stories,
      stats: e?.listing_stats,
      data: [
        ...(!!user.products.platforms['zameen']
          ? [
              {
                id: e.id,
                property_id: e?.id,
                slug: 'zameen',
                posted_on: e?.created_at,
                expiry_date: e?.expiry_date,
                status: tenantUtils.listingStatusMapper(e?.platform?.zameen?.status),
                statusKey: 'status',
                posted: postedToZameen(e?.platform?.['zameen']),
                views: e?.listing_stats?.imp_total,
                leads:
                  e?.listing_stats?.whatsapp_total +
                  e?.listing_stats?.sms_total +
                  e?.listing_stats?.email_total +
                  e?.listing_stats?.phone_total,
                clicks: e?.listing_stats?.views_count,
                ctr: (e?.listing_stats?.views_count / e?.listing_stats?.imp_total) * 100,
                calls: e?.listing_stats?.phone_total,
                emails: e?.listing_stats?.email_total,
                sms: e?.listing_stats?.sms_total,
                whatsapp: e?.listing_stats?.whatsapp_total,
                category: e?.platform?.['zameen']?.mark?.title,
                icon: 'IconZameen',
                public_url: e?.platform?.['zameen']?.public_url,
              },
            ]
          : []),
        ...(!!user.products.platforms['olx']
          ? [
              {
                id: e?.olx?.id,
                property_id: e?.id,
                slug: 'olx',
                posted: postedToOLX(e?.platform?.olx?.status),
                status: tenantUtils.listingStatusMapper(e?.platform?.olx?.status),
                statusKey: 'status',
                posted_on: e?.platform?.olx?.date_added,
                views: 'loading',
                leads: 'loading',
                clicks: 'loading',
                ctr: 'loading',
                calls: 'loading',
                emails: 'loading',
                sms: 'loading',
                whatsapp: 'loading',
                statsError: false,
                category: e?.platform?.olx?.mark?.title,
                public_url: e?.platform?.olx?.public_url,
                icon: 'IconOLX',
              },
            ]
          : []),
      ],
    },
  };
};

export const fetchListingPeformanceData = async (userId, params) => {
  const { [`filter[id]`]: listingId, page = '' } = params;
  const response = await NetworkService.get(
    `/api/listings/my_listings?${listingId ? `q[listing_id_eq]=${listingId}` : ''}&${page ? `page=${page}` : ''}`,
  );
  if (response?.error) {
    return { error: response.error };
  }
  const listingData = response?.data?.listings;
  const pagination = response?.data?.pagination;
  const user = store.getState().loginUser.user;

  return {
    list: listingData?.map((e) => tableMapper(e, user)),
    pagination: tenantUtils.getPaginationObject(pagination),
    table: [
      {
        title: 'Property',
        dataIndex: 'property',
        key: 'property',
        component: 'ListingPurpose',
        className: 'col-property',
      },
      {
        title: 'Platform',
        dataIndex: 'platforms',
        key: 'platform',
        component: 'Platform',
      },
      {
        title: 'Status',
        dataIndex: 'platforms',
        key: 'status',
        component: 'PlatformStatus',
      },
      {
        title: 'Posted On',
        dataIndex: 'platforms',
        key: 'posted_on',
        component: 'Date',
      },
      {
        title: 'Views',
        dataIndex: 'platforms',
        key: 'views',
        component: 'Stats',
      },
      {
        title: 'Clicks',
        dataIndex: 'platforms',
        key: 'clicks',
        component: 'Stats',
      },
      {
        title: 'All Leads',
        dataIndex: 'platforms',
        key: 'leads',
        component: 'Stats',
      },
      {
        title: 'Calls',
        dataIndex: 'platforms',
        key: 'calls',
        component: 'Stats',
      },
      {
        title: 'Whatsapp',
        dataIndex: 'platforms',
        key: 'whatsapp',
        component: 'Stats',
      },
      {
        title: 'Emails',
        dataIndex: 'platforms',
        key: 'emails',
        component: 'Stats',
      },
      {
        title: 'SMS',
        dataIndex: 'platforms',
        key: 'sms',
        component: 'Stats',
      },
      {
        title: 'Chat',
        dataIndex: 'platforms',
        key: 'chat',
        component: 'Stats',
      },
    ],
  };
};
