import tenantConstants from '@constants';
import tenantData from '@data';
import tenantUtils from '@utils';
import { NetworkService } from '../../../../services/networkService';
import { getErrorAllResponse } from '../../../../utility/utility';
import { listingMapper, olxMapper, postedToOLX, postedToZameen, zameenMapper } from './listingsApis';
import store from '../../../../redux/store';

const fieldType = { number: 'input', text: 'input', checkbox: 'checkbox', select: 'select' };

const sortIndex = { number: 3, text: 2, checkbox: 4, select: 1 };

const getAmenitiesData = (payload) => {
  const amenitiesData = {};
  payload &&
    payload.forEach((amenityItem, index) => {
      if (amenityItem.groups.length) {
        Object.defineProperty(amenitiesData, amenityItem.type_id, { value: { fields: {}, fieldsSections: [] } });
      }
      amenityItem.groups.forEach((e, i) => {
        amenitiesData?.[amenityItem.type_id].fieldsSections.push({
          title: tenantUtils.getLocalisedString(e, 'title'),
          fields: [],
        });
        // to sort
        const features = e.features.sort((first, second) => {
          return sortIndex[first.format] < sortIndex[second.format]
            ? -1
            : sortIndex[first.format] == sortIndex[second.format]
              ? 0
              : 1;
        });
        features.forEach((item) => {
          amenitiesData[amenityItem.type_id].fields[item.id] = {
            type: fieldType[item.format],
            initialValue: '',
            value: '',
            props: {
              options:
                fieldType[item.format] === 'select'
                  ? item?.options?.split(',').map((e) => ({ id: e, name: e }))
                  : undefined,
              label: tenantUtils.getLocalisedString(item, 'title'),
              horizontal: true,
            },
          };
          amenitiesData[amenityItem.type_id].fieldsSections[i].fields.push(item.id);
        });
      });
    });
  return amenitiesData;
};

export const fetchListingDetailToUpdate = async (userId, listingId) => {
  const response = await NetworkService.get(`/api/listings/${listingId}/edit`, {});
  const listing = response?.data?.listing;
  if (response) {
    if (response.error) {
      return response;
    } else {
      return {
        data: {
          listing: listing
            ? {
                ...listing,
                platform: {
                  zameen: postedToZameen(listing.platform?.zameen?.status),
                  olx: postedToOLX(listing?.platform?.olx?.status),
                },
              }
            : listing,
        },
      };
    }
  }
};

export const fetchCrossCityDetail = async (userId, cityId) => {
  const response = await NetworkService.get(`/api/listings/find_cross_city?subject_id=${userId}&city_id=${cityId}`, {});
  if (response) {
    if (response?.error) {
      return response;
    } else {
      const obj = response?.data?.CrossCity;
      return Object.keys(response?.data?.CrossCity)?.length > 0
        ? {
            data: {
              assigned_cities: obj?.assigned_cities,
              olx: {
                mapped: obj?.olx?.mapped,
                restricted: !obj?.olx?.mapped
                  ? false
                  : !obj?.olx?.bypass && obj?.olx?.cross_city_allowed - obj?.olx?.cross_city_consumed < 1,
              },
              zameen: {
                mapped: obj?.zameen?.mapped,
                restricted: !obj?.zameen?.mapped
                  ? false
                  : !obj?.zameen?.bypass && obj?.zameen?.cross_city_allowed - obj?.zameen?.cross_city_consumed < 1,
              },
            },
          }
        : undefined;
    }
  }
};

export const getS3PresignedUrl = async (attachmentType = 'listing_image', associationKey, fileName) => {
  const response = await NetworkService.get(
    `${
      attachmentType == 'listing_image' ? '/api/attachments/pre_signed_url' : '/api/assets/pre_signed_url'
    }?category=${attachmentType}&no_of_urls=${1}`,
    {},
  );
  return response;
};

export const getImageBank = async (userId, perPage = 30, page = 1) => {
  const response = await NetworkService.get(`/api/users/${userId}/image_bank?page=${page}&per_page=${perPage}`, {});
  if (response) {
    if (response.error) {
      return response;
    } else {
      return {
        data: {
          images: response.data.images,
          pagination: tenantUtils.getPaginationObject(response.data?.pagination),
        },
      };
    }
  }
  return response;
};

export const postSavedListing = async (listingId, action, listingStatus, userId) => {
  let response;
  if (
    action?.slug === 'post' ||
    action?.slug === 'repost' ||
    action?.slug === 'upgrade' ||
    listingStatus === 'post_to'
  ) {
    if (action?.platform === 'zameen') {
      response = await NetworkService.post(`/api/listings/${listingId}/post_to_zameen`, {
        user_id: userId,
      });
    } else if (action?.platform === 'olx') {
      response = await NetworkService.post(`/api/listings/${listingId}/post_to_olx`, {
        user_id: userId,
      });
    }
  } else if (action?.slug === 'publish') {
    if (action?.platform === 'zameen') {
      response = await NetworkService.post(`/api/listings/${listingId}/publish`, {
        user_id: userId,
      });
    } else if (action?.platform === 'olx') {
      response = undefined;
    }
  }
  if (response) {
    if (response?.error) {
      return response;
    } else if (!response?.data?.listing) {
      return {
        ...response,
        dontUpdate: true,
        message: `Listing ${action?.slug == 'publish' ? 'published' : 'posted'}  Successfully!`,
      };
    } else {
      const listing = response?.data?.listing;
      if (action?.platform === 'olx') {
        return {
          data: {
            ...olxMapper(listing, true),
            listing_id: listingId,
            replaceKey: 'platforms.data[1]',
            valueExists: true,
          },
        };
      } else {
        return {
          data: {
            ...zameenMapper(listing, true),
            listing_id: listingId,
            replaceKey: 'platforms.data[0]',
            valueExists: true,
          },
        };
      }
    }
  }
};

export const postNewListing = async (user, values) => {
  const locObj = values.location_select;
  const lat_long_source = locObj.map.type;

  const images = values.property_images?.length
    ? values.property_images
        .filter((e) => e.uuid && e.uploaded)
        .map(({ id, uuid, isMainImage }) => ({ uuid: uuid, name: uuid, is_main_image: !!isMainImage }))
    : [];

  const mainImageIndex = images.findIndex((e) => !!e.is_main_image && !e._destroy);
  let orderedImages = [];
  if (mainImageIndex != -1) {
    const mainImage = images.splice(mainImageIndex, 1);
    images.unshift(mainImage?.[0]);
  } else {
    let index = images.findIndex((e) => !e._destroy);
    images[index] = { ...images[index], is_main_image: true };
  }
  orderedImages = images?.length ? images.map((e, index) => ({ ...e, order: index })) : [];

  const payload = {
    subject_id: user?.id,
    listing: {
      purpose_id: values.purpose,
      type_id: values.property_type,
      ...(values.project_selection && { dev_id: values.project.id }),
      latitude: locObj.map.latitude,
      longitude: locObj.map.longitude,
      location_id: locObj?.location.location_id,
      ...(locObj.plot && { plot_no: locObj.plot.plot_number }),
      ...(locObj.plot?.id && { plotfinder_id: locObj.plot.id }),
      ...(lat_long_source && { lat_long_source }),
      title: values.property_title,
      description: values.property_description,
      possession_available: values.ready_for_possession,
      posted_on_zameen: !!values?.platform_selection?.zameen?.checked ? 1 : 0,
      posted_on_olx: !!values?.platform_selection?.olx?.checked ? 1 : 0,
      is_installment: values.installment_available,
      ...(values.installment_available && {
        advance_amount: values.advance_amount.value,
        remaining_installments: values.no_of_installments,
        monthly_amount: values.monthly_installment.value,
      }),
      listing_features_attributes: Object.keys(values?.feature_and_amenities || {})
        .filter((e) => !!values.feature_and_amenities[e])
        .map((e) => ({
          feature_id: e,
          feature_value: values.feature_and_amenities[e] === true ? 'Yes' : values.feature_and_amenities[e].toString(),
        })),
      ...(values.bedrooms && { beds: values.bedrooms }),
      ...(values.bathrooms && { bath: values.bathrooms }),
      price: values?.purpose == 1 ? values?.price?.value : values?.monthly_rent?.value,
      ...(!!values.monthly_rent.value && { rental_price: values.monthly_rent.value }),
      ...(!!values.monthly_rent.value && { rental_prices: { rental_price: values.monthly_rent.value } }),
      area_unit_value: values?.area?.value,
      land: (
        values.area.value *
        tenantData.areaMapings[tenantData?.areaUnitList.find((e) => e.id === values?.area?.unit)?.slug]?.['square_feet']
      ).toFixed(0),
      area_unit_id: values?.area?.unit,
      contact_person: user?.name,
      mail: values.email,
      mobile: values?.mobile && values?.mobile,
      phone: values?.landline,
      listing_images_attributes: orderedImages,
      listing_videos_attributes: values?.videos?.length
        ? values?.videos
            .filter((e) => e.id || (!e.id && !e._destroy))
            .map((e) => ({
              ...(e.id && { id: e.id }),
              video_host: 'youtube',
              video_title: '',
              video_link: e?.url,
              ...(e._destroy && { _destroy: e._destroy }),
            }))
        : [],
      source: tenantConstants.APP_SOURCE,
    },
  };

  const response = await NetworkService.post(`/api/listings`, payload);
  return response;
};

const findFeatureById = (arr, featureId) => {
  for (const group of arr) {
    const feature = group.features.find((feature) => feature.feature_id == featureId);
    if (feature) {
      return feature?.id;
    }
  }
  return null;
};

const getListingFeatures = (featuresAmenities, listingFeatures) =>
  featuresAmenities && Object.keys(featuresAmenities)?.length
    ? Object.keys(featuresAmenities)
        .map((e) =>
          !!findFeatureById(listingFeatures, e)
            ? {
                id: findFeatureById(listingFeatures, e),
                feature_id: e,
                ...(featuresAmenities?.[e] == undefined || featuresAmenities?.[e] == false
                  ? { _destroy: true }
                  : { feature_value: featuresAmenities?.[e] === true ? 'Yes' : featuresAmenities?.[e] }),
              }
            : featuresAmenities?.[e]
              ? { feature_id: e, feature_value: featuresAmenities?.[e] === true ? 'Yes' : featuresAmenities?.[e] }
              : null,
        )
        .filter((e) => !!e)
    : [];

export const updateListing = async (user, listingId, values, listing) => {
  const locObj = values.location_select;
  const lat_long_source = locObj.map.type;
  const images = values.property_images?.length
    ? values.property_images
        .filter((e) => e.id && e.uploaded)
        .map((image) => {
          const { id, uuid, isMainImage, _destroy } = image;
          return {
            name: uuid,
            is_main_image: !!isMainImage,
            ...(id &&
              typeof id === 'number' &&
              (image?.listing_id ? (image?.listing_id === listingId && { id }) : { id })),
            _destroy,
          };
        })
    : [];

  const mainImageIndex = images.findIndex((e) => !!e.is_main_image && !e._destroy);
  let orderedImages = [];
  if (mainImageIndex != -1) {
    const mainImage = images.splice(mainImageIndex, 1);
    images.unshift(mainImage?.[0]);
  } else {
    let index = images.findIndex((e) => !e._destroy);
    images[index] = { ...images[index], is_main_image: true };
  }

  orderedImages = images?.length ? images.map((e, index) => ({ ...e, order: index })) : [];

  const payload = {
    listing: {
      user_id: user?.id,
      purpose_id: values.purpose,
      type_id: values.property_type,
      latitude: locObj.map.latitude,
      longitude: locObj.map.longitude,
      location_id: locObj?.location.location_id,
      ...(locObj.plot && { plot_no: locObj.plot.plot_number }),
      ...(locObj.plot?.id && { plotfinder_id: locObj.plot.id }),
      ...(lat_long_source && { lat_long_source }),
      title: values.property_title,
      description: values.property_description,
      possession_available: values.ready_for_possession,
      is_installment: values.installment_available,
      advance_amount: values?.advance_amount?.value,
      remaining_installments: values?.no_of_installments,
      monthly_amount: values?.monthly_installment?.value,
      ...(values?.project?.id && { dev_id: values.project.id }),
      posted_on_zameen: values?.platform_selection?.zameen?.checked || false,
      posted_on_olx: values?.platform_selection?.olx?.checked || false,
      area_unit_value: values.area.value,
      area_unit_id: values.area.unit,
      land: (
        values.area.value *
        tenantData.areaMapings[tenantData?.areaUnitList.find((e) => e.id === values?.area?.unit)?.slug]?.['square_feet']
      ).toFixed(0),
      source: tenantConstants.APP_SOURCE,
      ...(values.bedrooms && { beds: values.bedrooms }),
      ...(values.bathrooms && { bath: values.bathrooms }),
      listing_features_attributes: getListingFeatures(values?.feature_and_amenities, listing?.listing_features),
      price: values?.purpose == 1 ? values?.price?.value : values?.monthly_rent?.value,
      contact_person: user?.name,
      mail: values.email,
      mobile: values?.mobile && values?.mobile,
      phone: values?.landline && values?.landline,
      ...(!!values?.monthly_rent?.value && { rental_price: values?.monthly_rent.value }),
      listing_images_attributes: images,
      listing_videos_attributes: values?.videos?.length
        ? values?.videos
            .filter((e) => e.id || (!e.id && !e._destroy))
            .map((e) => ({
              ...(e.id && { id: e.id }),
              video_host: 'youtube',
              video_title: '',
              video_link: e?.url,
              ...(e._destroy && { _destroy: e._destroy }),
            }))
        : [],
    },
  };

  let response = await NetworkService.put(`/api/listings/${listingId}`, payload);
  if (response) {
    return response;
  }
};

export const priceCheckApi = async (values) => {
  const areaUnitSlug = tenantData.areaUnitList.find((e) => e.id === values.area.unit)?.slug;
  const payload = {
    location_id: values.location_select?.location.location_id,
    type_id: values.property_type,
    purpose_id: values.purpose,
    area: (values.area.value * tenantData.areaMapings[areaUnitSlug]['square_feet']).toFixed(0),
    price: values?.monthly_rent.value ? values?.monthly_rent?.value : values.price.value,
  };
  const response = await NetworkService.get(`/api/listings/price_check`, payload);
  return response;
};

export const getQuotaDeductionApi = async (data) => {
  const response = await NetworkService.get(`/api/quotas/consumption`, data);
  if (response) {
    if (response?.error || Object.keys(response?.data?.quota)?.length === 0) {
      return { error: response?.error || 'Network Error' };
    } else {
      const obj = response?.data?.quota;
      return {
        ...(obj?.zameen && Object?.keys(obj?.zameen)?.length > 0
          ? {
              zameen: {
                available: Number(obj.zameen.available_quota?.toFixed(1)),
                required: Number(obj.zameen.factor?.toFixed(1)),
                isZoneArea: !!(obj.zameen?.factor > 1),
                zoneFactor: Number((obj.zameen.factor - 1).toFixed(1)),
                totalQuota: Number(obj.zameen.total_quota?.toFixed(1)),
                message: obj?.zameen?.message,
              },
            }
          : {
              zameen: {
                available: 0,
                required: 1,
                isZoneArea: false,
                zoneFactor: 0,
                totalQuota: 0,
                message: obj?.zameen?.message,
              },
            }),
        ...(obj?.olx &&
          Object?.keys(obj?.olx)?.length > 0 && {
            olx: {
              available: Number(obj.olx.available_quota?.toFixed(1)),
              required: Number(obj.olx.factor?.toFixed(1)),
              isZoneArea: !!(obj.olx?.factor > 1),
              zoneFactor: Number((obj.olx.factor - 1).toFixed(1)),
              totalQuota: Number(obj.olx.total_quota?.toFixed(1)),
              message: obj?.olx?.message,
            },
          }),
      };
    }
  }
};

export const getPhotographyVideoGraphyCredits = async (action, user) => {
  const promiseArray = [
    getCreditDeductionApi(action?.listing?.slug, {
      listing_id: action?.listing?.property_id,
      product_id: action?.product?.id,
      subject_id: action?.listing?.listingOwner ? action?.listing?.listingOwner?.id : user?.id,
    }),
    getCreditDeductionApi(action?.listing?.slug, {
      listing_id: action?.listing?.property_id,
      product_id: 13,
      subject_id: action?.listing?.listingOwner ? action?.listing?.listingOwner?.id : user?.id,
    }),
  ];

  const response = await Promise.all(promiseArray);
  const error = getErrorAllResponse(response);
  if (error) {
    return { error: response?.[0]?.error || response?.[1]?.error };
  }
  return {
    ['property_videography']: response?.[0]?.zameen,
    ['property_photography']: response?.[1]?.zameen,
  };
};

export const getCreditDeductionApi = async (platform, data) => {
  const response = await NetworkService.get(`/api/credits/consumption`, data);
  if (response) {
    if (!!response?.error) {
      return response;
    } else {
      const obj = response?.data?.credits;
      return {
        [platform]: {
          available: Number(obj?.available_qty?.toFixed(1)),
          required: Number(obj?.factor?.toFixed(1)),
          isZoneArea: !!(obj?.zone_factor > 1),
          zoneFactor: Number((obj?.factor - 1)?.toFixed(1)),
          totalQuota: Number(obj?.available_qty?.toFixed(1)),
        },
      };
    }
  }
};

export const listingHasUpgrades = (listing, user) => {
  return (
    (!!user?.platforms?.[0] && !listing?.platforms?.zameen) ||
    (!!user?.platforms?.[1] && !listing?.platforms?.olx) ||
    !!(listing?.platforms?.zameen && listing?.platforms?.zameen?.haveUpgrades) ||
    !!(listing?.platforms?.olx && listing?.platforms?.haveUpgrades)
  );
};

export const upsellListingMapper = (listing) => {
  const platforms = {};
  if (postedToZameen(listing.platform?.zameen?.status) || listing?.platform?.zameen?.status == 'limit') {
    platforms.zameen = {
      status: tenantUtils.listingStatusMapper(listing.platform?.zameen?.status),
      isFreeListing: !!(listing?.platform?.zameen?.status === 'limit'),
      haveUpgrades: !!tenantData.getListingActions('shot_listing', listing.platform, 'zameen')?.canApply,
      products: [
        {
          ...tenantData.getListingActions('shot_listing', listing.platform, 'zameen'),
          upgradeTitle: 'Make Listing Super Hot Listing',
          name: 'Super Hot Listing',
        },
        {
          ...tenantData.getListingActions('hot_listing', listing.platform, 'zameen'),
          upgradeTitle: 'Make Listing Hot Listing',
          name: 'Hot Listing',
        },
      ],
      description: 'Better reach on the platform',
    };
  }
  if (postedToOLX(listing?.platform?.olx?.status)) {
    platforms.olx = {
      ...listing.platform.olx,
      status: tenantUtils.listingStatusMapper(listing.platform.olx.status),
      haveUpgrades: !!tenantData.getListingActions('olx_feature', listing.platform, 'olx')?.canApply,
      description: 'Better reach on the platform',
      isFreeListing: false,
      products: [
        {
          ...tenantData.getListingActions('olx_feature', listing.platform, 'olx'),
          upgradeTitle: 'Make Listing Feature',
          name: 'Feature',
        },
      ],
    };
  }
  return platforms;
};

export const getListingDetailApi = async (userId, listingId, user) => {
  const response = await NetworkService.get(`/api/listings/${listingId}/edit`, {});
  if (response) {
    if (response.error) return response;
    else {
      const listing = response.data.listing;
      return {
        data: {
          ...listing,
          listingOwner: listing?.user,
          platforms: upsellListingMapper(listing),
          listingHasUpgrades: listingHasUpgrades(upsellListingMapper(listing), user),
          call: { title: '0800-ZAMEEN (92633)', number: '080092633' },
        },
      };
    }
  }
};

export const postPackageOnListing = async (listingId, action, data = {}) => {
  const user = store.getState().loginUser.user;
  let response;

  switch (action?.slug) {
    case 'shot_listing':
      response = await NetworkService.patch(`/api/listings/${listingId}/mark_superhot`, {
        ...data,
        subject_id: data?.user_id,
      });
      break;
    case 'hot_listing':
      response = await NetworkService.patch(`/api/listings/${listingId}/mark_hot`, {
        ...data,
        subject_id: data?.user_id,
      });
      break;
    case 'refresh_listing':
      response = await NetworkService.patch(`/api/listings/${listingId}/refresh`, {
        ...data,
        subject_id: data?.user_id,
      });
      break;
    case 'story_ad':
      response = await NetworkService.post(`/api/stories`, {
        ...data,
      });
      break;
    case 'olx_refresh_listing':
      response = await NetworkService.post(`/api/listings/${listingId}/apply_olx_credits`, {
        product_type: 'olx_refresh_listing',
        ...data,
      });
      break;
    case 'olx_feature':
      response = await NetworkService.post(`/api/listings/${listingId}/apply_olx_credits`, {
        product_type: 'olx_feature',
        ...data,
      });
      break;
    case 'property_videography':
      response = await NetworkService.patch(`/api/listings/${listingId}/verified_property_media`, {
        ...data,
        subject_id: data?.user_id,
      });
    default:
      break;
  }

  if (response) {
    if (response?.error) {
      return response;
    } else if (!response?.data?.listing) {
      return { ...response, dontUpdate: true, message: 'Credit applied Successfully!' };
    } else {
      const listing = response?.data?.listing;
      if (action?.platform === 'zameen') {
        return {
          data: {
            ...listingMapper(listing, user, true),
            listing_id: listingId,
            valueExists: true,
          },
        };
      } else {
        return {
          data: {
            ...olxMapper(listing, true),
            listing_id: listingId,
            replaceKey: 'platforms.data[1]',
            valueExists: true,
          },
        };
      }
    }
  }
};

export const deleteImageFromBank = async (images) => {
  const response = await NetworkService.delete(`/api/images`, {
    media_category_id: '7',
    images,
  });
  return response;
};

export const deleteImageFromS3 = async (imageItem) => {
  const response = await NetworkService.delete(`/api/images`, {
    media_category_id: '7',
    images: [imageItem?.id],
  });
  if (response) {
    return response;
  }
};

export const deleteStory = async (userId, storyId, listingId) => {
  const response = await NetworkService.delete(`/api/stories/${storyId}`);
  if (response.error) {
    return response;
  } else if (!!response?.data?.listing) {
    return {
      data: {
        ...zameenMapper(response?.data?.listing, true),
        listing_id: listingId,
        replaceKey: 'platforms.data[0]',
        valueExists: true,
      },
    };
  } else {
    return {
      dontUpdate: true,
      message: 'Story will be Removed Successfully!!',
    };
  }
};

export const getAmenities = async () => {
  const response = await NetworkService.get(`/api/amenities`, {});
  if (response) {
    if (response.error) {
      return response;
    } else {
      return {
        data: getAmenitiesData(response.data?.amenities),
      };
    }
  }
  return response;
};

export const getProjects = async (agencyId) => {
  const response = await NetworkService.get(`/api/projects?q[agency_id_eq]=${agencyId}`, {});
  if (response) {
    if (response.error) {
      return response;
    } else {
      return {
        data: response.data.projects,
      };
    }
  }
  return response;
};
