import { getAppLanguage } from '../../../hooks/useAppInit';
import { getClassifiedBaseURL, isDevelopment, isProduction } from '../../../utility/env';

const GET_LOGIN_PATH = (redirectedUrl) => {
  const locale = getAppLanguage().key;
  const path = appConstants.HAVE_LOGIN_SCREEN
    ? `${window.location.origin}/${locale}/signin`
    : `${getClassifiedBaseURL()}/${locale}/account?externalRedirectPath=${
        redirectedUrl ? redirectedUrl : `https://profolio.bayut.sa/${locale}`
      }`;
  return path;
};

const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

const getPaymentMethods = (methods, isMobile) => {
  const paymentMethods = {
    checkout: {
      key: 'creditDebit',
      label: 'Credit/Debit Card',
      icon: 'DebitCardIcon',
      size: 24,
      component: 'CreditDebitWithCheckout',
      hideNewBadge: true,
    },
    tamara: {
      key: 'tamara',
      label: 'Tamara',
      icon: 'TamaraLogo',
      size: 50,
      height: 25,
      component: 'Tamara',
    },
    tabby: {
      key: 'tabby',
      label: 'Tabby',
      icon: 'TabbyLogo',
      size: 50,
      height: 25,
      component: 'Tabby',
    },
    apple_pay: {
      key: 'applePay',
      label: 'Apple Pay',
      icon: 'ApplePayIcon',
      size: 50,
      component: 'ApplePay',
      hide: !isMobile && !isSafari(),
    },
  };

  let availableMethods = [];
  if (methods?.length) {
    methods?.forEach((e) => {
      if (e?.enabled && !paymentMethods?.[e?.slug]?.hide) {
        availableMethods.push({ ...paymentMethods?.[e?.slug], ...e });
      }
    });
  }
  console.log('===methods', methods, availableMethods);

  return availableMethods;
};

const languages = [
  { key: 'ar', label: 'العربية', labelShort: 'العربية', alternate: 'en', rtl: true, darkMode: false },
  { key: 'en', label: 'English', labelShort: 'EN', alternate: 'ar', rtl: false, darkMode: false },
];

const APP_LOGO = {
  title: 'Bayut',
  icon: { rtl: 'BayutAppArabicLogo', ltr: 'BayutAppLogo' },
  linkIos: {
    en: 'https://apps.apple.com/us/app/apple-store/id1504036401?pt=121218764&ct=app_adoption&mt=8',
    ar: 'https://apps.apple.com/ar/app/apple-store/id1504036401?pt=121218764&ct=app_adoption&mt=8',
  },
  linkPlayStore: {
    en: 'https://play.google.com/store/apps/details?id=com.bayut.bayutsaapp&referrer=utm_source%3Dprofolio%26utm_medium%3Dqr%26utm_campaign%3Dapp_adoption&hl=en',
    ar: 'https://play.google.com/store/apps/details?id=com.bayut.bayutsaapp&referrer=utm_source%3Dprofolio%26utm_medium%3Dqr%26utm_campaign%3Dapp_adoption&hl=ar',
  },
};

const IS_LITE_EXPERINCE = () => {
  return !!isProduction && !window.location.origin.includes('profolio');
};

const appConstants = Object.freeze({
  title: 'Bayut',
  locale: 'en-PK',
  country_code: 'SA',
  phoneRegex: /^\+9665\d{8}$/,
  // phoneRegex: /^\+966(?:(50|51|53|54|55|56|57|58|59)\d{7}|920[0-9]\d{5}|925[0-9]\d{5}|700\d{5}|800\d{7}|811\d{8}|830\d{9}|831\d{9}|891\d{8})$/,
  currency: 'SAR',
  timezone: 'Asia/Karachi',
  languages,
  KC_ENABLED: !isDevelopment,
  SHOW_ACCOUNT_MANAGER: true,
  SHOW_HELP_AND_SUPPORT: true,
  DAILY_RENTAL_ENABLED: true,
  SHOW_NATIONAL_ADDRESS: true,
  IS_CREDIT_CAPPING_ENABLED: true,
  HAS_MEMBER_AREA: true,
  AUTH_TOKEN_COOKIE_KEY: isProduction ? 'byt_cd' : 'access-token',
  USER_LOCAL_STORAGE_KEY: 'user_info',
  GET_LOGIN_PATH,
  HAVE_LOGIN_SCREEN: isDevelopment,
  CONVERT_TO_AGENCY: true,
  SHOW_REGA_DETAIL: true,
  LOCATION_LEVELS: { city: 2 },
  POST_LISTING_POPUP: true,
  SHOW_SEARCH_HEADER: false,
  LINK_TITLE: 'bayut.sa',
  getPaymentMethods,
  APP_LOGO,
  SHOW_AMENITIES_ICON: true,
  NUMBER_SYSTEM: 'western',
  ZENDESK: false,
  IS_LITE_EXPERINCE,
  FONT_FAMILY_LITE: 'Lato, Droid Arabic Kufi, sans-serif',
});

export default appConstants;
