import tenantRoutes from '@routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Flex, Image } from '../../../../components/common';
import { IconBayoutLogo, IconBayutArabic } from '../../../../components/utilities/icons';
import { useRouteNavigate } from '../../../../hooks';
import { getBaseURL, getClassifiedBaseURL } from '../../../../utility/env';
import { NavbarLinks } from './styled';

const NavBarNavigationContainer = ({ locale, isMobile }) => {
  const { t } = useTranslation();
  const pathLocale = locale == 'ar' ? '' : `/${locale}`;
  const user = useSelector((state) => state.loginUser.user);
  const navigate = useRouteNavigate();

  return isMobile ? (
    <Flex vertical>
      <a href={`${getClassifiedBaseURL()}/blog${pathLocale}`}>{t('Blog')}</a>
      <Link to={`${pathLocale}/companies`}>{t('Find an Agency')}</Link>
      <Link to={`${pathLocale}/new-projects`}>{t('New Projects')}</Link>
      {/* <Link to={`${pathLocale}/bayut-academy`}>{t('Bayut Academy')}</Link> */}
    </Flex>
  ) : (
    <div className="navbar-floor">
      <Flex className="container" align="center" justify="space-between" style={{ paddingBlock: '14px' }}>
        <Flex as="a" align="center" href={`${getClassifiedBaseURL()}${pathLocale}`}>
          {locale === 'en' ? <IconBayoutLogo width={125} height={32} /> : <IconBayutArabic width={125} height={32} />}
        </Flex>
        <NavbarLinks align="center" gap="22px">
          <a href={`${getClassifiedBaseURL()}/blog${pathLocale}`}>
            <Image
              src={
                locale === 'en'
                  ? `${getBaseURL()}/profolio-assets/images/bayut-blog-en-logo.png`
                  : `${getBaseURL()}/profolio-assets/images/bayut-blog-ar-logo.png`
              }
              style={{ width: '72px', paddingBlockStart: '4px' }}
            />
          </a>

          <a href={`${getClassifiedBaseURL()}${pathLocale}/companies/`}>{t('Find an Agency')}</a>
          <a href={`${getClassifiedBaseURL()}${pathLocale}/new-projects/`}>{t('New Projects')}</a>
          {/* <a href={`${getClassifiedBaseURL()}${pathLocale}/bayut-academy/`}>{t('Bayut Academy')}</a> */}

          <Button
            type="success"
            icon="MdAddLocationAlt"
            onClick={() => navigate(tenantRoutes.app('', false, user).post_listing.path)}
          >
            {t('Post a Listing')}
          </Button>
        </NavbarLinks>
      </Flex>
    </div>
  );
};

export default NavBarNavigationContainer;
