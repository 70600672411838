import tenantApi from '@api';
import tenantData from '@data';
import tenantUtils from '@utils';
import React, { lazy, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Group, Icon, Label, SelectSearch, Spinner, Tag } from '../../../../../components/common';
import { IconStyled } from '../../../../../components/common/icon/IconStyled';
import Algolia from '../../../../../services/algolia';
import { MapModal } from '../../../../../components/post-listing/map-modal/map-modal';
import { changeLocationClickEvent } from '../../../../../services/analyticsService';
const MapBox = lazy(() => import('../../../../../components/post-listing/mapbox/mapbox'));

const LocationSelect = (props) => {
  const {
    value,
    error,
    touched,
    setFieldValue,
    setFieldTouched,
    item,
    onCityChange = () => {},
    onPlotSelect,
    showPlot,
    disabled,
    forUpdate,
    crossCity,
    renderCrossCityAlerts,
    onLocationSelect,
    name,
    hideLocation,
    hidePlot,
    hideCrossCity,
    prefixIcon,
    labelIcon,
    showPrefixIcon = true,
    showLableIcon = true,
    user,
    formik,
    propertyType,
  } = props;
  const [cities, setCities] = useState([]);
  const [citiesLoading, setCitiesLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [locationsLoading, setLocationsLoading] = useState(false);
  const [isPlotFinderExists, setShowPlotFinder] = useState(false);
  const { t } = useTranslation();

  const plotFinderRef = useRef();
  const locationRef = useRef();
  const modalRef = useRef();

  useEffect(() => {
    if (value?.city?.name) {
      fetchSelectedCity(value?.city?.name);
    }
  }, [value?.city?.name]);

  useEffect(() => {
    if (formik?.values?.['location']?.id) {
      getSubLocations();
    } else if (value?.city) {
      getLocations();
    }
  }, [formik?.values?.['location'], value?.city]);

  useEffect(() => {
    fetchCities();
  }, []);

  const fetchChildLocations = async (location, withParent = false, callbackSuccess = () => {}, callback = () => {}) => {
    if (!!location) {
      try {
        setLocationsLoading(true);
        const queryObject = withParent
          ? Algolia.getLocationsChildsFromLevelWithParent(location.id, location.level)
          : Algolia.getLocationsChildsFromLevel(location.id, location.level);
        const response = await Algolia.getLocationsIndex().search('', queryObject);
        setLocationsLoading(false);
        if (response) {
          callbackSuccess(response);
          return response;
        } else {
          callback();
        }
      } catch (e) {
        callback();
      }
    } else {
      callback();
      return;
    }
  };

  const fetchSelectedCity = async (name) => {
    setCitiesLoading(true);
    const queryObject = Algolia.getCitiesByName(name);
    const response = await Algolia.getLocationsIndex().search('', queryObject);
    if (response) {
      setCitiesLoading(false);
      setCities([
        ...(response?.hits?.length
          ? response?.hits?.map((e) => ({
              ...e,
              city_title: tenantUtils.getLocalisedString(e, 'title'),
            }))
          : []),
      ]);
    }
  };
  const fetchCitiesOnSearch = async (text, callbackSuccess = () => {}, callback) => {
    try {
      let response = null;
      if (!!text) {
        const queryObject = Algolia.getCitiesByName(text);
        response = await Algolia.getLocationsIndex().search('', queryObject);
      } else {
        const queryObject = Algolia.getAllCities();
        response = await Algolia.getLocationsIndex().search('', queryObject);
      }

      if (response) {
        callbackSuccess(response);
        return response;
      } else {
        callback();
      }
    } catch (e) {
      callback();
    }
  };

  const getLocations = async () => {
    const res = await fetchChildLocations(value?.city);
    setLocations(
      res?.hits?.length
        ? res?.hits?.map((e) => ({
            ...e,
            title: tenantUtils.getLocalisedString(e, 'title'),
            value: e?.location_id,
          }))
        : [],
    );
  };
  const getSubLocations = async () => {
    const res = await fetchChildLocations(formik?.values?.['location'], true);
    setLocations(
      res?.hits?.length
        ? res?.hits?.map((e) => ({ ...e, title: tenantUtils.getLocalisedString(e, 'title'), value: e?.location_id }))
        : [],
    );
  };

  const fetchCities = async () => {
    setCitiesLoading(true);
    const queryObject = Algolia.getAllCities();
    const response = await Algolia.getLocationsIndex().search('', queryObject);
    if (response) {
      setCitiesLoading(false);
      setCities(
        response?.hits?.length
          ? response?.hits?.map((e) => ({
              ...e,
              city_title: tenantUtils.getLocalisedString(e, 'title'),
            }))
          : [],
      );
      response.hits;
    }
  };

  const fetchLocations = async (text, callbackSuccess = () => {}, callback) => {
    if (!!text) {
      try {
        const queryObject = Algolia.getLocationsByName(value?.city?.location_id, text);
        const response = await Algolia.getLocationsIndex().search('', queryObject);
        if (response) {
          callbackSuccess(response);
          return response;
        } else {
          callback();
        }
      } catch (e) {
        callback();
      }
    } else {
      callback();
      return;
    }
  };

  const onChangeCity = (cityValue, option) => {
    let locationOBJ = {};
    if (option) {
      if (value?.city?.location_id != option.location_id) {
        plotFinderRef.current && plotFinderRef.current.clearOptions();
        locationRef.current && locationRef.current.clearOptions();
        onCityChange(option);
        locationOBJ['city'] = { ...option, id: option?.city_id };
      } else {
        locationOBJ = value;
      }
    } else {
      setShowPlotFinder(false);
    }
    setFieldValue(
      item.key,
      { ...locationOBJ, map: option ? { longitude: option.longitude, latitude: option.latitude, type: 'city' } : null },
      true,
    );
  };

  const onChangeLocation = (locationValue, option) => {
    if (option.location_id != value.location?.location_id) {
      setFieldValue(item.key, {
        city: value.city,
        location: option,
        map: option ? { longitude: option.longitude, latitude: option.latitude, type: 'location' } : null,
      });
      plotFinderRef.current && plotFinderRef.current.clearOptions();
      onLocationSelect(option);
    } else if (!option) {
      setShowPlotFinder(false);
    }
  };

  const onChangePlotNumber = (val, option) => {
    if (option) {
      setFieldValue(item.key, {
        ...value,
        plot: option,
        map: {
          longitude: option.geometry.coordinates[0],
          latitude: option.geometry.coordinates[1],
          type: 'plotfinder',
        },
      });
      onPlotSelect(option);
    } else {
      setFieldValue(item.key, {
        ...value,
        plot: null,
        map: {
          longitude: Number(value?.location.longitude),
          latitude: Number(value?.location.latitude),
          type: 'location',
        },
      });
      onPlotSelect(null);
    }
  };

  const onConfirmMapLocation = ({ latitude, longitude }, val = value) => {
    setFieldValue(item.key, { ...val, map: { longitude, latitude, type: 'map' } }, true);
    changeLocationClickEvent(user, formik?.values, !forUpdate);
  };

  const onCancelMapModal = () => {
    const longitude = value?.map?.longitude || value.location?.longitude;
    const latitude = value?.map?.latitude || value.location?.latitude;
    setFieldValue(item.key, { ...value, map: { longitude, latitude, type: 'map' } }, true);
  };

  const getMapPosition = useCallback(() => {
    return {
      latitude: (value?.map ? value.map : value?.location)?.latitude,
      longitude: (value?.map ? value.map : value?.location)?.longitude,
    };
  }, [value?.map, value?.location]);

  const renderLocationTag = () => {
    const locationChip = [value?.plot?.plot_number && `Plot # ${value?.plot?.plot_number}`, value.location, value.city]
      .filter((e) => !!e)
      .map((e) => e?.title?.en || e?.title || e)
      .join(', ');
    return (
      <Group template="max-content auto" gap="16px">
        <IconStyled>
          <Icon icon="MdOutlineMap" />
        </IconStyled>
        <Group template="initial" gap="8px">
          <Label>{t('Location')}</Label>
          <div>
            <Tag shape="round" bordered disabled>
              {value.location && locationChip}
            </Tag>
          </div>
        </Group>
      </Group>
    );
  };

  return (
    <Group gap="32px" template="1fr">
      {forUpdate ? (
        renderLocationTag()
      ) : (
        <>
          <Group gap={hideLocation ? '8px' : '0'}>
            <SelectSearch
              name={item.key}
              onChange={onChangeCity}
              onBlur={() => setFieldTouched(item.key, { city: true })}
              label={t('City')}
              placeholder={t('Select City')}
              fetchApi={fetchCitiesOnSearch}
              payloadKey="hits"
              filterOption={false}
              value={value?.city?.location_id}
              prefixIcon={!!showPrefixIcon && 'MdCircle'}
              labelIcon={!!showLableIcon && 'MdOutlinePlace'}
              initialOptions={cities}
              getOptionValue={(e) => e.location_id}
              getOptionLabel={(e) => tenantUtils.getLocalisedString(e, 'title')}
              loading={citiesLoading}
              allowClear
              valueAsObj
              errorMsg={
                (error?.city && touched?.city && error?.city) ||
                (!!touched && Object.keys(touched).length === 0 && error?.city)
              }
              disabled={disabled?.city}
            />
            {!hideCrossCity && (
              <div style={{ marginInlineStart: 52, marginBlockStart: 8 }}>{renderCrossCityAlerts()}</div>
            )}
          </Group>
          {!hideLocation && (
            <div>
              <SelectSearch
                fetchApi={fetchLocations}
                payloadKey="hits"
                disabled={!value?.city?.location_id || !formik?.values?.is_location_editable}
                valueAsObj
                getOptionValue={(e) => e.location_id}
                getOptionLabel={(e) => tenantUtils.getLocalisedString(e, 'title')}
                filterOption={false}
                value={value?.location?.location_id}
                onChange={onChangeLocation}
                onBlur={() => setFieldTouched(item.key, { city: true, location: true })}
                initialOptions={locations}
                label={t('Location/District')}
                labelIcon="MdOutlineMap"
                placeholder={
                  !value?.city?.location_id
                    ? t('Search Location')
                    : t('Search from ') + tenantUtils.getLocalisedString(value?.city, 'title') ||
                      tenantUtils.getLocalisedString(value?.city, 'name')
                }
                prefixIcon="location"
                errorMsg={
                  (error?.location && touched?.location && error?.location) ||
                  (!!touched && Object.keys(touched).length === 0 && error?.location)
                }
                ref={locationRef}
              />
            </div>
          )}
        </>
      )}

      {showPlot && (
        <>
          {/* <SelectOnMap
            imgUrl="/profolio-assets/images/map.png"
            onClick={() => {
              modalRef && modalRef.current.showModal();
            }}
            disabled={!value?.location}
            style={{ marginInlineStart: 50 }}
            className={!value?.location && 'pointerDisabled'}
            type="button"
          >
            <Icon className="mb-8" icon="HiLocationMarker" size="2em" />
            {!!value?.plot || forUpdate ? t('Location on Map') : t('Choose Location on Map')}
          </SelectOnMap> */}
          <Suspense fallback={<Spinner />}>
            <MapBox
              onClick={() => {
                modalRef && modalRef.current.showModal();
                changeLocationClickEvent(user, formik?.values, !forUpdate);
              }}
              disabled={disabled?.location}
              readOnly={true}
              {...getMapPosition()}
            />
          </Suspense>
          <MapModal
            modalRef={modalRef}
            value={value}
            forUpdate={forUpdate}
            onCancelMapModal={onCancelMapModal}
            onConfirmMapLocation={onConfirmMapLocation}
            getMapPosition={getMapPosition}
            user={user}
            propertyType={propertyType}
          />
        </>
      )}
    </Group>
  );
};
export default LocationSelect;
