export const agencyUser = values => {
  return {
    user: {
      name: values?.name,
      email: values?.email,
      ...(values?.password && { pass: values?.password }),
      mobile: values?.mobile && values?.mobile,
      phone: values?.landline && values?.landline,
      address: values?.address,
      country_id: values?.country || 155,
      city_id: values?.city,
      user_profile_id:
        !!values?.image && !!values?.image?.length ? values?.image?.[0]?.uuid || values?.image?.[0]?.id : '',
    },
  };
};

export const permissions = (userId, values) => {
  return {
    user_id: userId,
    privs: {
      listings: values.listings ? 1 : 0,
      stats: values.stats ? 1 : 0,
      leads: values.leads ? 1 : 0,
      inbox: values.inbox ? 1 : 0,
      staff: values.staff ? 1 : 0,
      profile: values.profile ? 1 : 0,
    },
  };
};

export const loggedInUser = values => {
  return {
    user: {
      name: values?.name,
      mobile: values?.mobile,
      address: values?.address,
      country_id: values?.country || 155,
      city_id: values?.location?.city?.location_id,
      user_profile_id:
        !!values?.profile_image && !!values?.profile_image.length
          ? values?.profile_image?.[0]?.uuid || values?.profile_image?.[0]?.id
          : '',
      update_user_listing_details: !!values?.updatePropertyListings,
      ...(!!values?.phone && { phone: values?.phone }),
      ...(!!values?.whatsapp && { whatsapp: values?.whatsapp }),
    },
  };
};

export const preferencesUpdate = values => {
  return {
    preferences: {
      email_notification: values?.email_notification,
      newsletter: values?.newsletter,
      automated_reports: values?.automated_reports,
      currency: values?.currency,
      area_unit: values?.area_unit,
    },
  };
};

export const agencySettings = (values, updatingAgencyUser) => {
  return updatingAgencyUser
    ? {
        agency: {
          ...(values?.owner?.name && { owner_name: values?.owner?.name }),
          ...(values?.owner?.designation && { designation: values?.owner?.designation }),
          ...(values?.owner?.message && { message: values?.owner?.message }),
          owner_attributes: {
            id: values?.owner?.ownerId,
            name: values?.owner?.name,
            user_profile_id: !!values?.owner?.profilePhoto?.length
              ? values?.owner?.profilePhoto[0]?.uuid || values?.owner?.profilePhoto[0]?.id
              : null,
          },
        },
      }
    : {
        agency: {
          name: values?.companyTitle,
          country_id: values?.country,
          city_id: values?.city?.city?.location_id,
          address: values?.address,
          mobile: values?.mobile && values?.mobile,
          use_agency_whatsapp: !!values?.agencyWhatsappForAll ? values?.agencyWhatsappForAll : 0,
          agency_logo_id: !!values?.logo?.length ? values?.logo[0]?.uuid || values?.logo[0]?.id : null,
          update_agency_listing_details: !!values?.updatePropertyListings ? values?.updatePropertyListings : 0,
          ...(!!values?.email && { email: values?.email }),
          ...(!!values?.website && { website: values?.website }),
          ...(!!values?.description && { description: values?.description }),
          ...(values?.landline && { phone: values?.landline }),
          ...(!!values?.whatsapp && { whatsapp: values?.whatsapp }),
        },
      };
};

export const userPassword = values => {
  return {
    user: {
      current_password: values?.oldPassword,
      new_password: values?.newPassword,
      new_password_confirmation: values?.confirmPassword,
    },
  };
};
