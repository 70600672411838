import tenantConstants from '@constants';
import tenantData from '@data';
import { imageStateObject } from '../../../../helpers/imageHelpers/imageStateObject';
import { propertyTypes, skipFieldsForField } from './listing-form-data';

const fetchAmenities = (listing) => {
  const amenities = {};
  if (listing?.listing_features && listing?.listing_features?.length > 0) {
    listing.listing_features?.forEach((e, index) => {
      listing?.listing_features?.[index]?.features?.forEach((it) => {
        amenities[it.feature_id] =
          it?.value == 'YES' || it?.value == 'Yes' || it?.value == 'yes' || it?.value == 'true' ? true : it?.value;
      });
    });
  }
  return amenities;
};

export const getInitialValues = (listing, user) => {
  const selectedCity = listing?.location?.breadcrumb?.find((e) => e.level == tenantConstants.LOCATION_LEVELS?.city);

  const getSelectedSubType = (typeId) => {
    let propertyType = null;
    Object.keys(propertyTypes).forEach((key) => {
      const foundItem = propertyTypes[key].find((item) => item.id === typeId);
      if (foundItem) propertyType = foundItem;
    });
    return propertyType;
  };

  const bedInitialValue = () => {
    return skipFieldsForField?.['bedrooms'].property_type[listing?.listing_type?.id]?.studio && listing?.beds == -1
      ? null
      : listing?.beds
        ? listing?.beds?.toString()
        : null;
  };

  const amenities = fetchAmenities(listing);

  return {
    ...listing,
    property_type: listing?.listing_type?.id,
    property_title_en: listing?.title,
    property_title_ar: listing?.title_l1,
    property_description_en: listing?.description,
    property_description_ar: listing?.description_l1,
    bedrooms: bedInitialValue(),
    bathrooms: listing?.baths ? listing?.baths?.toString() : null,
    furnished: listing?.is_furnished ? '1' : '0',
    residence_type: listing?.residence_type
      ? listing?.residence_type?.id
      : listing?.residenceTypes?.length
        ? listing?.residenceTypes[0]?.id
        : null,
    feature_and_amenities: listing?.listing_features.length ? amenities : undefined,
    property_images: listing?.images
      ? listing?.images.map((e) => ({
          ...e,
          ...(e?.default && { isMainImage: true }),
          ...imageStateObject(),
        }))
      : [],
    videos: [],
    posting_as: listing?.contact_detail,
    mobile: user?.mobile || '+966',
    price: listing?.price,
    rental_price: listing?.price,
    rental_frequency: listing?.rent_frequency ? listing?.rent_frequency?.id : null,
    latitude: listing?.latitude || listing?.location?.latitude || 23.8859, //default lattitude for SA
    longitude: listing?.longitude || listing?.location?.longitude || 45.0792, //default longitude for SA
    location: {
      ...listing?.location,
      value: listing?.location?.id,
      level: listing?.location?.level,
    },
    sub_listing_type: getSelectedSubType(listing?.listing_type?.id),
    'location-info': selectedCity
      ? {
          city: {
            ...selectedCity,
            name: selectedCity?.title,
            location_id: selectedCity?.id,
          },
          location: {
            ...listing?.location,
            location_id: listing?.location?.id,
            level: listing?.location?.breadcrumb[listing?.location?.breadcrumb?.length - 1]?.level - 1,
          },
        }
      : { map: { latitude: 24.774265, longitude: 46.738586, type: 'map' } },
  };
};

export const getRentalInitialValues = (listing, user) => {
  const selectedCity = listing?.location?.breadcrumb?.find((e) => e.level == tenantConstants.LOCATION_LEVELS?.city);

  const propertyTypeInitialValue = tenantData.listingTypes()?.find((e) => e.id === listing?.listing_purpose?.id)
    ?.sub_types[0].id;

  const amenities = fetchAmenities(listing);

  return {
    ...listing,
    property_type: listing?.listing_type?.id ? listing?.listing_type?.id : propertyTypeInitialValue,
    property_type_label: listing?.listing_type?.title
      ? listing?.listing_type?.title
      : tenantData.listingTypes()?.find((e) => e.id === listing?.listing_purpose?.id)?.sub_types[0]?.title?.en,
    property_title_en: listing?.title,
    property_title_ar: listing?.title_l1,
    property_description_en: listing?.description,
    property_description_ar: listing?.description_l1,
    bedrooms: listing?.beds ? listing?.beds?.toString() : null,
    bathrooms: listing?.baths ? listing?.baths?.toString() : null,
    national_address: listing?.national_address ? listing?.national_address : null,
    feature_and_amenities: listing?.listing_features.length ? amenities : undefined,
    property_images: listing?.images
      ? listing?.images.map((e) => ({
          ...e,
          ...(e?.default && { isMainImage: true }),
          ...imageStateObject(),
        }))
      : [],
    posting_as: listing?.contact_detail,
    mobile: user?.mobile || '+966',
    price: listing?.price ? listing?.price : '',
    area: listing?.area_unit?.value ? listing?.area_unit?.value : '',
    latitude: listing?.latitude || listing?.location?.latitude || 23.8859, //default lattitude for SA
    longitude: listing?.longitude || listing?.location?.longitude || 45.0792, //default longitude for SA
    location: {
      ...listing?.location,
      value: listing?.location?.id,
      level: listing?.location?.level,
    },
    'location-info': selectedCity
      ? {
          city: {
            ...selectedCity,
            name: selectedCity?.title,
            location_id: selectedCity?.id,
          },
          location: {
            ...listing?.location,
            location_id: listing?.location?.id,
            level: listing?.location?.breadcrumb[listing?.location?.breadcrumb?.length - 1]?.level - 1,
          },
        }
      : { map: { latitude: 24.774265, longitude: 46.738586, type: 'map' } },
  };
};
