import { Area } from './table-components/area';
import { AvatarName } from './table-components/avatar';
import { Date } from './table-components/date';
import { ExpandableMessage } from './table-components/expandable-message';
import { ExpiryRenewal } from './table-components/expiry-renewal';
import { Health } from './table-components/health';
import { Img } from './table-components/image';
import { ListingPurpose } from './table-components/listing-purpose';
import { OrderActions } from './table-components/order-actions';
import { OrderStatus } from './table-components/order-status';
import { Platform } from './table-components/platform';
import { PlatformListingActions } from './table-components/platform-listing-actions';
import { PlatformStatus } from './table-components/platforms-status';
import { Price } from './table-components/price';
import { Products } from './table-components/products';
import { ListingStats } from './table-components/listing-stats';
import { StaffDetails } from './table-components/staff-details';
import { Stats } from './table-components/stats';
import { String } from './table-components/string';
import { TableListingActions } from './table-components/table-listing-actions';
import { TableNumber } from './table-components/table-number';
import { UserRowActions } from './table-components/user-row-actions';

export default {
  AvatarName,
  Image: Img,
  String,
  Number: TableNumber,
  Date,
  PlatformStatus,
  Price,
  Area,
  Health,
  Platform,
  PlatformListingActions,
  TableListingActions,
  UserRowActions,
  Stats,
  OrderActions,
  OrderStatus,
  Products,
  ListingPurpose,
  ExpiryRenewal,
  ExpandableMessage,
  ListingStats,
  StaffDetails,
};
