import tenantTheme from '@theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Icon, Number, Popover, Skeleton } from '../../common';
import Group from '../../common/group/group';

export const ListingStats = (props) => {
  const { t } = useTranslation();
  const { data = [] } = props;

  const renderStats = (label, stat, data) => {
    return (
      <Flex align="center" gap="8px">
        <div className="text-muted fz-12">{t(label)}</div>
        {stat === 'loading' ? <Skeleton size="small" /> : stat ? <Number value={stat} compact={false} /> : '0'}
        {label === 'Leads' && (
          <Popover
            content={
              <div style={{ width: 120 }}>
                <Flex justify="space-between">
                  <span>{t('Calls')}</span>
                  <span>{data?.calls ? data?.calls : 0}</span>
                </Flex>
                <Flex justify="space-between">
                  <span>{t('Whatsapp')}</span>
                  <span>{data?.whatsapp ? data?.whatsapp : 0}</span>
                </Flex>
                <Flex justify="space-between">
                  <span>{t('SMS')}</span>
                  <span>{data?.sms ? data?.sms : 0}</span>
                </Flex>
                <Flex justify="space-between">
                  <span>{t('Email')}</span>
                  <span>{data?.emails ? data?.emails : 0}</span>
                </Flex>
              </div>
            }
            action="hover"
          >
            <>
              <Icon icon="AiOutlineInfoCircle" size="1em" color={tenantTheme['primary-color']} />
            </>
          </Popover>
        )}
      </Flex>
    );
  };

  return (
    <Group gap="8px">
      {data.map((item) => (
        <div key={item.slug}>
          {renderStats('Views', item?.views)}
          {renderStats('Clicks', item?.clicks)}
          {renderStats('Leads', item?.leads, item)}
        </div>
      ))}
    </Group>
  );
};
