import tenantConstants from '@constants';
import tenantRoutes from '@routes';
import tenantTheme from '@theme';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TenantComponents from '../..';
import { Button, Icon, LinkWithIcon, Popover, TextInput } from '../../../../components/common';
import { IconSwitch } from '../../../../components/svg';
import { useGetLocation, useRouteNavigate } from '../../../../hooks';
import { addPropertyEvent } from '../../../../services/analyticsService';
import { getClassifiedBaseURL } from '../../../../utility/env';
import { stSlash } from '../../../../utility/utility';
import DownloadBayutModal from '../downloadAppModal/downloadAppModal';
import { AtbdTopDropdwon } from './style';
import { downloadAppButtonClick } from '../../../../services/analyticsService';

const HeaderComponent = () => {
  const { t } = useTranslation();
  const rtl = useSelector((state) => state.AppConfig.rtl);
  const { user } = useSelector((state) => state.loginUser);
  const { pathname: path } = useGetLocation();
  const currentPathSlug = path.split('/').pop();
  const navigate = useRouteNavigate();
  const [value, setValue] = useState('');
  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  const locale = useSelector((state) => state.AppConfig.locale);
  const [modal, setModal] = useState(false);

  const onSearch = () => {
    navigate(`${tenantRoutes.app().listings.path}?q[listing_id_eq]=${value}`);
  };

  const content = (
    <AtbdTopDropdwon
      className="search-widget grid align-items-center"
      style={{ '--template': 'auto auto', gap: '10px' }}
    >
      <TextInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={t('Search By ID')}
        prefixIcon="BiSearch"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onSearch(e.target.value);
          }
        }}
        autoFocus
      />
      <Button type="primary" size="large" onClick={onSearch} style={{ height: '40px' }}>
        {t('Search')}
      </Button>
    </AtbdTopDropdwon>
  );

  return (
    <>
      {!isMobile && (
        <>
          <Button
            className="px-0"
            type="link"
            icon="MdPhoneIphone"
            iconSize="1.3em"
            onClick={() => {
              setModal(true);
              downloadAppButtonClick(user);
            }}
          >
            {t('Download App')}
          </Button>
          <DownloadBayutModal visible={modal} onClose={() => setModal(false)} />
          <LinkWithIcon
            as="a"
            icon={<IconSwitch />}
            linkTitle={`${t('Go to bayut.sa')}`}
            href={`${getClassifiedBaseURL()}/${locale}`}
            className="btnLink px-8"
            target="_blank"
          />

          <Button
            type="default"
            outlined={false}
            icon={'SideMenuProperty'}
            iconColor={tenantTheme['primary-color']}
            onClick={() => {
              navigate(`${stSlash('')}/listings`);
            }}
          >
            {t('My Listings')}
          </Button>

          <Button
            type="primary"
            icon="MdOutlineAddLocationAlt"
            onClick={() => {
              navigate(tenantRoutes.app('', false, user).post_listing.path);
              addPropertyEvent(user, currentPathSlug, false);
            }}
          >
            {t('Post Listing')}
          </Button>
        </>
      )}
      {tenantConstants.SHOW_SEARCH_HEADER && (
        <Popover placement={rtl ? 'bottomLeft' : 'bottomRight'} content={content} action="click">
          <Button type="default" style={{ paddingInline: '.65em', alignSelf: 'stretch' }}>
            <Icon icon="BiSearch" />
          </Button>
        </Popover>
      )}
    </>
  );
};

export default HeaderComponent;
