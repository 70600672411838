const getBaseURL = () => process.env.REACT_APP_BASE_URL || '';
const getClassifiedBaseURL = () => process.env.REACT_APP_CLASSIFIED_URL;
const getAPIBaseURL = () => process.env.REACT_APP_API_ENDPOINT;
const getAPIKey = () => process.env.REACT_APP_API_KEY;
const getKCBaseURL = () => process.env.REACT_APP_KEYCLOAK_BASE_URL;
const isProduction =
  process.env.REACT_APP_ENVIRONMENT === 'production' || process.env.REACT_APP_ENVIRONMENT === 'prelive';
const isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'development';
const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;
const TENANT_KEY = process.env.REACT_APP_TENANT || 'zameen';
const kcClientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
const kcRealm = process.env.REACT_APP_KEYCLOAK_REALM;
const broadCastChannelName = process.env.REACT_APP_BROADCAST_CHANNEL_NAME;

export {
  getBaseURL,
  getClassifiedBaseURL,
  getAPIKey,
  getAPIBaseURL,
  isProduction,
  isDevelopment,
  cookieDomain,
  TENANT_KEY,
  getKCBaseURL,
  kcClientId,
  kcRealm,
  broadCastChannelName,
};
