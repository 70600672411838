import { NetworkService } from "../../../../services/networkService";
import tenantData from '@data';
import tenantUtils from '@utils'

export const listingCardMapper = listing => {
  return {
    id: listing?.id,
    ...(listing?.listing_purpose && { purpose: listing?.listing_purpose }),
    type: listing?.listing_type,
    area: { value: listing?.area_unit?.value, unit: listing?.area_unit?.id || 2 },
    location: {
      ...listing?.location,
      breadcrumb: listing?.location?.breadcrumb
        ?.reverse()
        ?.filter((e) => e?.level > 1)
        ?.map((e) => tenantUtils.getLocalisedString(e, 'title'))
        ?.join(', '),
    },
    image: listing?.images?.find((item) => item?.is_main_image) || listing?.images?.[0],
    price: { value: listing?.price, currency: 'SAR' },
    details: {
      ...listing?.rega_details?.property_specs,
      listingSpecs: tenantData?.getListingSpecs(listing),
      listing_type: listing?.listing_type,
      regaId: listing?.ad_license,
      regaExpiryDate: listing?.platforms?.rega_expiry_date,
    },
    ...(listing?.health && {
      health: listing?.health,
    }),
    disposition: listing?.disposition?.slug,
  };
};

export const getListingCardData = async listingId => {
  const response = await NetworkService.get(`/api/listings/${listingId}/edit`);
  if (response) {
    if (response.error) {
      return response;
    } else {
      return listingCardMapper(response?.data?.listing);
    }
  }
};
