import React from 'react';
import { Icon, Popover, Tag } from '../../common';
import Group from '../../common/group/group';

export const PlatformStatus = (props) => {
  const { data = [] } = props;
  return (
    <Group gap="8px" template="auto">
      {data?.map((item) => (
        <div key={item?.slug}>
          <Tag color={item?.[item?.statusKey]?.color} shape="round">
            {item?.[item?.statusKey]?.label}
            {item?.[item?.statusKey]?.comments && (
              <Popover
                placement="right"
                title="Comments"
                content={<p style={{ paddingInline: 10 }}>{item?.[item?.statusKey]?.comments}</p>}
                action="hover"
              >
                <Icon icon="AiOutlineInfoCircle" style={{ marginInlineStart: 5 }} color="currentColor" />
              </Popover>
            )}
          </Tag>
        </div>
      ))}
    </Group>
  );
};
