import { Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { DrawerModal, Modal } from '../common';
import SuccessModalContent from './successModalContent';
const { Text } = Typography;

const SuccessModal = ({ visible, handleCancel = () => {}, ...rest }) => {
  return (
    <DrawerModal
      type="primary"
      visible={visible}
      okButtonProps={{ type: 'primary' }}
      onCancel={handleCancel}
      width={510}
      footer={null}
    >
      <SuccessModalContent {...rest} />
    </DrawerModal>
  );
};

export default SuccessModal;
