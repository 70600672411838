import tenantRoutes from '@routes';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CardCompact } from '../common/cards/styled';
import SuccessModal from '../success-modal/success-modal';
import { ListingPurpose } from '../table/table-components/listing-purpose';
import { useRouteNavigate } from '../../hooks';
import { fetchUserDetail } from '../../redux/user/actionCreator';
import { getListingCardData } from '../../tenant/apis/bayut/listings/getListingCardData';
import { getBaseURL, getClassifiedBaseURL } from '../../utility/env';

const SuccessfulPaymentModal = ({ data, setData }) => {
  const listingId = data?.listing_id;
  const { t } = useTranslation();
  const user = useSelector((state) => state.loginUser.user);
  const { locale, isMemberArea } = useSelector((state) => state.AppConfig);
  const dispatch = useDispatch();
  const navigate = useRouteNavigate();
  const [listing, setListing] = useState(null);

  const key = !!listingId ? 'listing' : 'shop';
  const fetchListingCardData = async () => {
    if (listingId) {
      const response = await getListingCardData(listingId);
      if (response) {
        if (!response.error) {
          setListing(response);
        }
      }
    }
  };

  useEffect(() => {
    fetchListingCardData();
  }, [key]);

  const modalData = useMemo(
    () => ({
      listing: {
        title: t('Listing Submitted!'),
        description: {
          'pending-nafaz-verification': t(
            'Almost there! Complete your Nafath Verification to publish your listing live.',
          ),
          default: t('Congratulations! Your listing has been submitted successfully'),
        },
        buttons: [
          { btnText: t('Go to Listings'), url: tenantRoutes.app().listings.path, type: 'default' },
          ...(!user.is_nafaz_verified
            ? [
                {
                  btnText: t('Verify Nafath'),
                  href: `${getClassifiedBaseURL()}/${locale}/verification/nafath/?redirectPath=${getBaseURL()}`,
                  type: 'primary',
                },
              ]
            : []),
        ],
      },
      shop: {
        title: t('Credits Purchased'),
        description: { default: t('Congratulations! You have purchased credits') },
        buttons: [
          isMemberArea
            ? { btnText: t('Go to Listings'), url: tenantRoutes.app().listings.path }
            : { btnText: t('Go to Dashboard'), url: tenantRoutes.app().dashboard.path },
        ],
      },
    }),
    [user],
  );

  const onCloseModal = () => {
    setData(null);
    dispatch(fetchUserDetail(user?.id));
    if (modalData?.[key]?.buttons?.[0]?.url) {
      navigate(modalData?.[key]?.buttons?.[0]?.url);
    } else if (modalData?.[key]?.buttons?.[0]?.href) {
      window.location.href = modalData?.[key]?.buttons?.[0]?.href;
    }
  };

  const handleButtonClick = (item) => {
    setData(null);
    dispatch(fetchUserDetail(user?.id));
    item?.url && navigate(item?.url);
    window.location.reload();
  };

  return (
    <SuccessModal
      title={modalData?.[key]?.title}
      description={modalData?.[key]?.description?.[listing?.disposition] || modalData?.[key]?.description?.default}
      buttons={modalData?.[key]?.buttons}
      handleButtonClick={handleButtonClick}
      handleCancel={onCloseModal}
      visible={!!data}
      renderContent={() =>
        listing && (
          <CardCompact style={{ borderRadius: 8 }}>
            <ListingPurpose {...listing} thumbnailStyles={{ borderRadius: 4 }} />
          </CardCompact>
        )
      }
    />
  );
};

export default SuccessfulPaymentModal;
