import tenantData from '@data';
import tenantApi from '@api';
import tenantRoutes from '@routes';
import tenantTheme from '@theme';
import tenantFilters from '@filters';
import { Card, Tabs } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Group } from '../../../../components/common/index.js';
import { TabsStyled } from '../../../../components/common/radio-button/styled.js';
import { ListingCardContainer } from '../../../../components/listing-card-container/ListingCardContainer.js';
import { ListingContainer } from '../../../../components/listing-container/ListingContainer';
import PostListingButton from '../../../../components/post-listing-button/post-listing-button.js';
import { ListingBanner } from '../../../../components/styled.js';
import { EmptyListing } from '../../../../components/svg.js';
import CreditsQuota from '../../../../components/widgets/credits-quota/credits-quota.js';
import { Main } from '../../../../container/styled';
import { useFetchOnQueryUpdate, useGetLocation, usePageTitle, useRouteNavigate } from '../../../../hooks';
import { fetchExpiryData, fetchQuotaCreditsData } from '../../../../redux/dashboard/actionCreators.js';
import { fetchListings, setListingId } from '../../../../redux/listings/actionCreator';
import { pageViewMyListingEvent } from '../../../../services/analyticsService/index.js';
import { getBaseURL } from '../../../../utility/env';
import { mapQueryStringToFilterObject } from '../../../../utility/urlQuery';
import listingStates from './listings.json';
import { useSearchParams } from 'react-router-dom';

const Listings = () => {
  const { t } = useTranslation();

  usePageTitle(
    t('Your Property Listings Dashboard | Bayut KSA'),
    t(
      "Your one-stop listings page for the ads you have posted on Bayut! Whether you're selling your villa or renting out an apartment, easily view, manage, upgrade and analyze all your property listings in one place.",
    ),
  );
  const { user } = useSelector((state) => state.loginUser);
  const listingsData = useSelector((state) => state.listings);
  const { isMobile, isMemberArea, isMultiPlatform, locale } = useSelector((state) => state.AppConfig);
  const users = useSelector((state) => state.userGroup.list);
  const { quotaCreditsData } = useSelector((state) => state.Dashboard);
  const dispatch = useDispatch();
  const location = useGetLocation();
  const navigate = useRouteNavigate();
  const { queryObj } = mapQueryStringToFilterObject(location.search);
  const [tab, setTab] = useState(queryObj?.['q[status_id_eq]'] || listingsData?.statuses?.[0]?.key?.toString());
  const [searchParams, setSearchParams] = useSearchParams();
  const [truCheckStatus, setTruCheckStatus] = useState();

  const fetchTruCheckStatuses = async () => {
    const response = await tenantApi.getTruCheckStatus();
    !response?.error && setTruCheckStatus(response);
  };

  const filtersList = useMemo(
    () => tenantFilters.getMyListingsFilters(user, users, locale, truCheckStatus),
    [user, users, locale, truCheckStatus],
  );

  const { fetchData } = useFetchOnQueryUpdate(
    (params) => {
      if (user?.id) {
        dispatch(fetchListings(user.id, params, filtersList, isMobile ? '' : 'desktop'));
      }
    },
    [user?.id],
  );

  useEffect(() => {
    fetchTruCheckStatuses();
    return () => {
      dispatch(setListingId({ data: [], selected: false, multiple: false, clearSelectedItems: true }));
    };
  }, []);

  // useEffect(() => {
  //   if (Object.keys(queryObj).length && !queryObj?.['q[status_id_eq]']) {
  //     onChangeTab(
  //       listingsData?.statuses?.find((e) => e.total != 0)?.key?.toString() ||
  //         listingsData?.statuses?.[0]?.key?.toString(),
  //     );
  //   }
  // }, [listingsData?.statuses]);

  useEffect(() => {
    setTab(queryObj?.['q[status_id_eq]'] || listingsData?.statuses?.[0]?.key?.toString());
  }, [listingsData, location.search]);

  useEffect(() => {
    if (isMemberArea) {
      if (Object.keys(quotaCreditsData.selectedUser).length) {
        tenantData.platformList.map((platform) => {
          const selectedUser = quotaCreditsData.selectedUser[platform.slug]
            ? quotaCreditsData.selectedUser[platform.slug]
            : user?.permissions?.[PERMISSIONS_TYPE.PROFILE]
              ? -1
              : user?.id;
          fetchQuotaCredits(selectedUser, platform.slug);
        });
      } else {
        fetchQuotaCredits(user?.id);
      }
    }
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const statusId = query.get('q[status_id_eq]');
    if (statusId) {
      pageViewMyListingEvent(user, statusId);
    } else {
      pageViewMyListingEvent(user, '10');
    }
  }, [location.search]);

  const onChangeTab = (e) => {
    const obj = { ...queryObj, [listingsData?.tabFilterKey]: e };
    const decodedParams = Object.entries(obj).reduce((acc, [key, value]) => {
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {});
    setSearchParams(decodedParams);
  };

  const fetchQuotaCredits = async (id, platformKey) => {
    dispatch(fetchExpiryData(id, platformKey, user));
    dispatch(fetchQuotaCreditsData(id, platformKey, user));
  };

  const emptyState = useMemo(
    () => ({
      title: listingStates.listingStates[tab]?.title,
      subtitle: listingStates.listingStates[tab]?.subtitle,
      illustration: <EmptyListing color={tenantTheme['primary-light-2']} />,
      button: <PostListingButton />,
    }),
    [tab],
  );

  const renderStatesTabs = useCallback(() => {
    return (
      listingsData.statuses && (
        <TabsStyled
          className="mb-0"
          activeKey={tab || listingsData?.statuses?.[0]?.key?.toString()}
          onChange={(e) => {
            onChangeTab(e);
          }}
        >
          {listingsData?.statuses?.map((e) => (
            <Tabs.TabPane tab={e?.tab} key={e?.key} />
          ))}
        </TabsStyled>
      )
    );
  }, [listingsData.statuses, tab]);

  const renderQuotaCreditsMobile = (platformKey) => {
    return (
      <CreditsQuota
        skeletonLoading={!quotaCreditsData?.data && quotaCreditsData?.loading}
        loading={quotaCreditsData?.loading}
        platform={platformKey}
        {...quotaCreditsData?.data?.[platformKey]}
        title={t(quotaCreditsData?.data?.[platformKey]?.title)}
        error={quotaCreditsData?.error}
        expiryData={quotaCreditsData.productExpiryData?.[platformKey]}
        downgradedData={user?.contracts?.[platformKey]}
        accentColor={quotaCreditsData?.data?.[platformKey]?.platform?.brandColor}
        {...(isMobile ? { cardHeight: '0px' } : { widgetGap: '20px', rowHeight: '0px' })}
        bodyPadding={!isMobile && '16px'}
      />
    );
  };

  const renderBanner = () =>
    isMemberArea && (
      <Card bodyStyle={{ padding: isMobile && 0 }}>
        <Group template={!isMobile ? 'repeat(2, 1fr)' : ''} gap="12px" style={{ alignItems: 'center' }}>
          <ListingBanner
            bannerPath={`${getBaseURL()}/profolio-assets/bayut/lite/banner-faces-2.jpg`}
            template={'1fr'}
            breakTag={false}
            className={'listing-banner align-items-start'}
            titleFontWeight="700"
            listColor="#fff"
            titleColor="#fff"
            listItems={false}
            title={t('Get a business package and enjoy exclusive benefits')}
            subtitle={t('Access Profolio, manage agency staff, view lead reports and much more')}
            titleAsH1
            href={tenantRoutes.app('', false, user).prop_shop.path}
          />
          {user?.platforms.map((platform) => {
            return isMultiPlatform ? (
              <Flex vertical gap="24px">
                <div>{renderQuotaCreditsMobile(platform?.slug)}</div>
              </Flex>
            ) : (
              <div>{renderQuotaCreditsMobile(platform?.slug)}</div>
            );
          })}
        </Group>
      </Card>
    );

  return (
    <>
      {isMobile ? (
        <Main style={{ paddingBlock: '0 8px' }}>
          <ListingCardContainer
            listingsData={listingsData}
            renderTableTopRight={renderStatesTabs}
            pagination={listingsData?.pagination}
            renderFiltersAsTopRight
            fetchData={fetchData}
            skeletonLoading={!listingsData?.list && !!listingsData.loading}
            showPagination
            loading={listingsData.loading}
            error={listingsData?.error}
            emptyState={emptyState}
            renderBanner={renderBanner}
            filtersList={filtersList}
          />
        </Main>
      ) : (
        <>
          <ListingContainer
            filtersList={filtersList}
            listingsData={listingsData}
            listingApi={fetchListings}
            activeTab={Number(tab)}
            renderTableTopRight={listingsData.statuses}
            onChangeTab={onChangeTab}
            loading={listingsData.loading}
            error={listingsData.error}
            onRetry={fetchData}
            isMemberArea={isMemberArea}
            title={isMemberArea ? t('My Listings') : undefined}
            emptyState={emptyState}
            renderBanner={renderBanner}
            disableTabsOnLoading
          />
        </>
      )}
    </>
  );
};

export default Listings;
