import tenantUtils from '@utils';
import { Button, Card, Col, Row, Tag } from 'antd';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationModal, Icon } from '../../../../components/common';
import ProgressBar from '../../../../components/common/progress-bar/ProgressBar';
import { setListingId } from '../../../../redux/listings/actionCreator';
const ApplyBulkRefresh = ({ fetchData }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.loginUser);
  const dispatch = useDispatch();

  const { selectedListings } = useSelector((state) => state.listings);
  const [applied, setApplied] = useState(0);
  const [refreshCalled, setRefreshCalled] = useState(0);
  const [selectedPlatorm, setSeletedPlatform] = useState(null);
  const isMobile = useSelector((state) => state.AppConfig.isMobile);

  const bulkRefreshRef = useRef();
  const progressBarRef = useRef();

  const renderApplyRefreshConfirmation = () => {
    return (
      <>
        {isMobile ? (
          !selectedListings?.length ? (
            <></>
          ) : (
            <Card className={'selectedRowsMobile'} bodyStyle={{ padding: 20 }}>
              <div className="flex justify-content-between align-items-center mb-16">
                <div>
                  <Tag color="#00A4D1">{selectedListings.length}</Tag>
                  {t('Listings Selected')}
                </div>
                <Button
                  onClick={() => {
                    dispatch(setListingId({ data: [], selected: false, multiple: false, clearSelectedItems: true }));
                  }}
                  transparented
                  block={false}
                  style={{ borderColor: '#fff', padding: '4px 8px' }}
                >
                  {t('Cancel')}
                </Button>
              </div>
              <div className="flex justify-content-between align-items-center">
                {user?.platforms?.map((e) => {
                  return (
                    <Button
                      onClick={() => {
                        setSeletedPlatform(e?.slug);
                        bulkRefreshRef?.current && bulkRefreshRef?.current?.showModal();
                      }}
                      style={{ padding: '4px 8px' }}
                    >
                      <Icon icon={e?.icon} size="1.4em" style={{ verticalAlign: 'text-bottom' }} />
                      {e?.slug === 'zameen' ? t('Refresh Listings') : t('Boost To Top')}
                    </Button>
                  );
                })}
              </div>
            </Card>
          )
        ) : (
          !!selectedListings.length && (
            <Card className={isMobile ? 'selectedRowsMobile' : 'selectedRows'} bodyStyle={{ padding: 20 }}>
              <Row gutter={48} className="pi-32">
                <Col>
                  <Tag color="#00A4D1">{selectedListings.length}</Tag>
                  {t('Listings Selected')}
                </Col>
                {user?.platforms?.map((e) => {
                  return (
                    <Col>
                      <div className="span-all">
                        <Button
                          onClick={() => {
                            setSeletedPlatform(e?.slug);
                            bulkRefreshRef?.current && bulkRefreshRef?.current?.showModal();
                          }}
                          block
                          style={{ padding: '4px 8px' }}
                        >
                          <Icon icon={e?.icon} size="1.4em" style={{ verticalAlign: 'text-bottom' }} />
                          {e?.slug === 'zameen' ? t('Refresh Listings') : t('Boost To Top')}
                        </Button>
                      </div>
                    </Col>
                  );
                })}
                <Col style={{ marginInlineStart: 'auto' }}>
                  <Button
                    onClick={() => {
                      dispatch(setListingId({ data: [], selected: false, multiple: false, clearSelectedItems: true }));
                    }}
                    transparented
                    block
                    style={{ borderColor: '#fff', padding: '4px 8px' }}
                  >
                    {t('Cancel')}
                  </Button>
                </Col>
              </Row>
            </Card>
          )
        )}
      </>
    );
  };

  return (
    <>
      <ConfirmationModal
        ref={bulkRefreshRef}
        onCancel={() => {
          dispatch(setListingId({ data: [], selected: false, multiple: false, clearSelectedItems: true }));
          bulkRefreshRef?.current && bulkRefreshRef?.current?.hideModal();
        }}
        onSuccess={async () => {
          progressBarRef?.current && progressBarRef?.current?.showModal();
          bulkRefreshRef?.current && bulkRefreshRef?.current?.hideModal();
          await tenantUtils.applyBulkRefresh(user?.id, selectedListings, selectedPlatorm, setApplied, setRefreshCalled);
        }}
      >
        <div>
          {`${t('Do you want to apply')} ${selectedPlatorm === 'zameen' ? t('Refresh') : t('Boost')} ${t(
            'Credits to the selected',
          )}
          ${selectedListings?.length} ${t('Listings')}?`}
        </div>
        <div>
          {t(
            'Zone factor and other cases will be handled in the background and the process will stop if the credits are exhausted.',
          )}
        </div>
      </ConfirmationModal>

      <ConfirmationModal
        ref={progressBarRef}
        onCancel={() => {
          fetchData();
          dispatch(setListingId({ data: [], selected: false, multiple: false, clearSelectedItems: true }));
          setApplied(0);
          setRefreshCalled(0);
          progressBarRef?.current && progressBarRef?.current?.hideModal();
        }}
        title={selectedPlatorm === 'zameen' ? t('Refresh Progress') : t('Boost to Top Progress')}
        footer={<></>}
      >
        <ProgressBar
          done={refreshCalled}
          successfull={applied}
          total={selectedListings?.length}
          selectedPlatorm={selectedPlatorm}
        />
      </ConfirmationModal>
      {renderApplyRefreshConfirmation()}
    </>
  );
};
export default ApplyBulkRefresh;
