import { Group, Tag } from "../../common";

export const OrderStatus = (props) => {
  const { state } = props;
  return (
    <Group gap="8px" template="auto">
      <div>
        <Tag color={state?.color} shape="round">
          {state?.label}
        </Tag>
      </div>
    </Group>
  );
};

