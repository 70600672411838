import React, { useEffect } from 'react';
import { Spinner } from '../../../components/common';
import { useGetLocation } from '../../../hooks';
import { convertQueryObjToString, mapQueryStringToFilterObject } from '../../../utility/urlQuery';
import { Main } from '../../styled';
import { useNavigate } from 'react-router-dom';

function PaymentProcess(props) {
  const navigate = useNavigate();
  const { search } = useGetLocation();
  const {
    'cko-session-id': sessionId = null,
    order_id = null,
    status = null,
    id = null,
  } = mapQueryStringToFilterObject(search)?.queryObj;

  useEffect(() => {
    if (sessionId || order_id) postSessionId();
  }, [sessionId, order_id]);

  const paymentType = status ? status : id ? 'success' : 'error';

  const postSessionId = async () => {
    if (sessionId) {
      window.parent.postMessage({ paymentType, sessionId, paymentChannel: 'checkout' }, '*');
    } else {
      navigate(`/checkout?${convertQueryObjToString({ paymentChannel: 'hyperPay', paymentType, order_id })}`);
    }
  };

  return (
    <Main>
      <Spinner type="full" />
    </Main>
  );
}

export default PaymentProcess;
