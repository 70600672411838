import { emailValidationYup, phoneValidationYup, stringValidationYup } from '../../../helpers';

export const profileFormFields = (t, data, KC_ENABLED) => {
  const basicFields = {
    name: {
      type: 'input',
      value: '',
      validation: () => stringValidationYup(t('Please Enter name')).nullable(),
      props: {
        label: 'Title (English)',
        placeholder: 'Please enter English title',
        maxLength: 100,
      },
    },
    nameArabic: {
      type: 'input',
      value: '',
      props: {
        label: 'العنوان (عربي)',
        placeholder: 'Please enter Arabic title',
        maxLength: 100,
        groupStyle: {
          direction: 'rtl',
        },
      },
    },
    location: {
      type: 'location-select',
      value: null,
      props: {
        label: 'City',
        placeholder: 'Select City',
        hideCrossCity: true,
        hideLocation: true,
        hidePlot: true,
        showSearch: false,
        showPrefixIcon: false,
        showLableIcon: false,
        options: [],
      },
    },
    whatsapp: {
      type: 'phone-input',
      value: '',
      validation: () => phoneValidationYup(false),
      props: {
        label: 'Whatsapp',
        placeholder: 'Enter whatsapp number',
        defaultCountry: 'SA',
        countryCallingCodeEditable: false,
        countrySelectProps: { disabled: true },
        // extra: <Icon></Icon>,
      },
    },
    nationalShortAddress: {
      type: 'input',
      value: '',
      validation: () => stringValidationYup(false).nullable(),
      props: {
        label: 'National Short Address',
        placeholder: 'Enter National Short Address',
        maxLength: 8,
        className: 'span-all',
      },
    },
    address: {
      type: 'input',
      value: '',
      validation: () => stringValidationYup(false).nullable(),
      props: {
        label: 'Address',
        placeholder: 'Enter Address',
        maxLength: 250,
        className: 'span-all',
      },
    },

    profile_image: {
      type: 'image-select',
      value: [],
      validation: () => null,
      props: {
        label: 'Upload a picture',
        placeholder: 'Browse and Upload',
        multi: false,
        className: 'span-all',
        attachmentType: 'user_profile',
      },
    },
  };
  const kcFormFields = {
    email: {
      type: 'input',
      value: '',
      validation: data?.email ? false : () => emailValidationYup().nullable(),
      props: {
        label: 'Email',
        placeholder: 'Enter Email',
        maxLength: 300,
      },
    },

    mobile: {
      type: 'phone-input-verification',
      value: '',
      validation: () => phoneValidationYup(true),
      props: {
        containerClassName: 'pos-rel',
        label: 'Phone Number',
        placeholder: 'Enter Phone Number',
        defaultCountry: 'SA',
        countrySelectProps: { disabled: true },
        isUserVerified: !!data?.is_mobile_verified,
        phoneNumber: data?.mobile,
        userId: data?.id,
        userType: 'User',
      },
    },
  };

  const allFields = {
    name: {
      type: 'input',
      value: '',
      validation: () => stringValidationYup(t('Please Enter name')).nullable(),
      props: {
        label: 'Title (English)',
        placeholder: 'Please enter English title',
        maxLength: 100,
      },
    },
    nameArabic: {
      type: 'input',
      value: '',
      props: {
        label: 'العنوان (عربي)',
        placeholder: 'Please enter Arabic title',
        maxLength: 100,
        groupStyle: {
          direction: 'rtl',
        },
      },
    },
    email: {
      type: 'input',
      value: '',
      validation: data?.email ? false : () => emailValidationYup().nullable(),
      props: {
        label: 'Email',
        placeholder: 'Enter Email',
        maxLength: 300,
        disabled: data?.email,
      },
    },
    location: {
      type: 'location-select',
      value: null,
      props: {
        label: 'City',
        placeholder: 'Select City',
        hideCrossCity: true,
        hideLocation: true,
        hidePlot: true,
        showSearch: false,
        showPrefixIcon: false,
        showLableIcon: false,
        options: [],
      },
    },
    mobile: {
      type: 'phone-input-verification',
      value: '',
      validation: () => phoneValidationYup(true),
      props: {
        containerClassName: 'pos-rel',
        label: 'Phone Number',
        placeholder: 'Enter Phone Number',
        defaultCountry: 'SA',
        countrySelectProps: { disabled: true },
        isUserVerified: !!data?.is_mobile_verified,
        phoneNumber: data?.mobile,
        userId: data?.id,
        userType: 'User',
      },
    },

    whatsapp: {
      type: 'phone-input',
      value: '',
      validation: () => phoneValidationYup(false),
      props: {
        label: 'Whatsapp',
        placeholder: 'Enter whatsapp number',
        defaultCountry: 'SA',
        countryCallingCodeEditable: false,
        countrySelectProps: { disabled: true },
      },
    },
    nationalShortAddress: {
      type: 'input',
      value: '',
      validation: () => stringValidationYup(false).nullable(),
      props: {
        label: 'National Short Address',
        placeholder: 'Enter National Short Address',
        maxLength: 8,
        className: 'span-all',
      },
    },
    address: {
      type: 'input',
      value: '',
      validation: () => stringValidationYup(false).nullable(),
      props: {
        label: 'Address',
        placeholder: 'Enter Address',
        maxLength: 250,
        className: 'span-all',
      },
    },

    profile_image: {
      type: 'image-select',
      value: [],
      validation: () => null,
      props: {
        label: 'Upload a picture',
        placeholder: 'Browse and Upload',
        multi: false,
        className: 'span-all',
        attachmentType: 'user_profile',
      },
    },
  };
  return KC_ENABLED ? { basicFields, kcFormFields } : { basicFields: allFields };
};
