import tenantTheme from '@theme';
import { Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Icon, Tag, TextWithIcon } from '../../common';
import { IconStyled } from '../../common/icon/IconStyled';
import RenderTextLtr from '../../render-text/render-text';

const { Text } = Typography;

export const StaffDetails = (props) => {
  const { t } = useTranslation();
  const { name, email, phone, user_role, image } = props;
  return (
    <Flex gap="10px" align="center">
      <div>
        {image ? (
          <Avatar size={34} src={image} style={{ margin: 0, padding: 1 }} />
        ) : (
          <IconStyled style={{ '--icon-styled-width': '40px' }}>
            <Icon size={16} icon="FiUser" />
          </IconStyled>
        )}
      </div>

      <div>
        {name && (
          <Text className="fw-700" style={{ paddingInlineEnd: '8px' }}>
            {name}
          </Text>
        )}
        {user_role == 'owner' && (
          <Tag color="#28B16D" textColor="#fff" gradient style={{ '--tag-font-size': '12px', '--line-height': 1.6 }}>
            {t('Owner')}
          </Tag>
        )}

        {email && (
          <TextWithIcon
            icon="MdMail"
            iconProps={{ size: '12px', color: tenantTheme.gray500 }}
            textColor={tenantTheme.gray700}
            value={email}
            textSize={'12px'}
          />
        )}
        {phone && (
          <TextWithIcon
            icon="MdPhone"
            iconProps={{ size: '12px', color: tenantTheme.gray500 }}
            textColor={tenantTheme.gray700}
            value={<RenderTextLtr text={phone} />}
            textSize={'12px'}
          />
        )}
      </div>
    </Flex>
  );
};

