import tenantConstants from '@constants';
import { Tooltip } from 'antd';
import cx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Icon, Modal } from '../../../../components/common';
import { IconArabicBold, IconArabicfont } from '../../../../components/utilities/icons';
import { useAppAuthentication, useRouteNavigate } from '../../../../hooks';
import { fetchFavoriteProperties, saveUserSettings } from './apis';
import { DeskTopNavBar } from './desktopNavbar';
import { MobileNavBar } from './mobileNavBar';
import { FAB } from './styled';
import { getLink, setCookieUnits } from './utils';
import tenantRoutes from '@routes';
import tenantApi from '@api';
import LiteNoDataModalUi from '../../../../components/common/lite-no-data-modal/lite-no-data-modal-ui';

export const NavBar = () => {
  const { t } = useTranslation();
  const { isMobile, rtl } = useSelector((state) => state.AppConfig);
  const userData = useSelector((state) => state?.loginUser?.user);
  const locale = useSelector((state) => state.AppConfig.locale);

  const [newLocale, setNewLocale] = useState(locale);
  const [showLocaleModal, setShowLocaleModal] = useState(false);
  const [showChangeCurrencyModal, setShowChangeCurrencyModal] = useState(false);
  const [showChangeAreaUnitModal, setShowChangeAreaUnitModal] = useState(false);
  const [savedSearches, setSavedSearches] = useState([]);
  const [favouriteProperties, setFavouriteProperties] = useState([]);
  const [isFavpropLoading, setIsFavpropLoading] = useState(false);
  const [noSaveSearchModal, setNoSaveSearchModal] = useState(false);
  const [noFavPropModal, setNoFavPropModal] = useState(false);
  const { onLogout, auth } = useAppAuthentication();
  const navigate = useRouteNavigate();

  const pathLocale = locale == 'ar' ? '' : `/${locale}`;

  const countryList = useMemo(
    () => [
      {
        title: 'United Arab Emirates',
        title_l1: 'الإمارات العربية المتحدة',
        href: 'https://www.bayut.com/',
        id: 'UAE',
        icon: 'AE',
      },
      {
        title: 'Pakistan',
        title_l1: 'باكستان',
        href: 'https://www.zameen.com/',
        id: 'PK',
        icon: 'PK',
      },
      {
        title: 'Jordan',
        title_l1: 'الأردن',
        href: 'https://www.bayut.jo/',
        id: 'JO',
        icon: 'JO',
      },
      {
        title: 'Philippines',
        title_l1: 'الفلبين',
        href: 'https://www.lamudi.com.ph/',
        id: 'PH',
        icon: 'PH',
      },
      {
        title: 'Indonedia',
        title_l1: 'إندونيسيا',
        href: 'https://www.lamudi.co.id/',
        id: 'ID',
        icon: 'ID',
      },
      { title: 'Egypt', title_l1: 'مَصر', href: 'https://www.bayut.eg/', id: 'EG', icon: 'EG' },
    ],
    [],
  );

  const localeData = useMemo(
    () => [
      { label: 'English', value: 'en' },
      { label: newLocale === 'ar' ? <IconArabicBold size={52} /> : <IconArabicfont size={52} />, value: 'ar' },
    ],
    [newLocale],
  );

  const logoutUser = async () => {
    onLogout();
  };

  const saveSettings = async (payload) => {
    setCookieUnits(payload);
    await saveUserSettings(payload);
  };

  const toggleLanguage = (loc) => {
    const currentUrl = window.location.pathname;
    let newUrl;
    if (currentUrl.includes(`/${locale}/`)) {
      newUrl = currentUrl.replace(`/${locale}/`, `${loc == 'ar' ? '' : `/${loc}`}/`);
    } else {
      newUrl = `/${loc}${window.location.pathname}`;
    }
    return newUrl;
  };

  const languageObjectToSwitch = useMemo(
    () => tenantConstants?.languages?.length > 1 && tenantConstants?.languages?.find((e) => e.alternate === locale),
    [locale],
  );

  const getNoFavouritePropertyModalUi = () => {
    return (
      <LiteNoDataModalUi
        visible={noFavPropModal}
        onCancel={() => setNoFavPropModal(false)}
        icon="IconFavouriteProperties"
        title={t("You haven't added any favourite properties yet")}
        subtitle={t('Simply tap on the HEART icon to bookmark your favourite properties and explore them later')}
      />
    );
  };

  useEffect(() => {
    fetchFavProperties();
  }, [auth?.authenticated]);

  const fetchFavProperties = async () => {
    if (auth?.authenticated) {
      setIsFavpropLoading(true);
      const response = await fetchFavoriteProperties();
      if (!!response.data) {
        setFavouriteProperties(
          response.data?.map((e) => ({
            value: e,
            label: getLink(e, `/property/details-${e}.html`, true, null, locale),
          })),
        );
      }
      setIsFavpropLoading(false);
    }
  };

  const getNoSavedSearchesModalUi = () => {
    return (
      <LiteNoDataModalUi
        visible={noSaveSearchModal}
        onCancel={() => setNoSaveSearchModal(false)}
        icon="IconSavedSearched"
        title={t("You haven't saved any searches yet")}
        subtitle={t('Use the SAVE SEARCH button below the filters to bookmark your property preferences')}
      />
    );
  };

  return (
    <>
      {getNoFavouritePropertyModalUi()}
      {getNoSavedSearchesModalUi()}
      <Tooltip {...(isMobile && { open: false })} title={t('Post an Ad')} placement={rtl ? 'left' : 'right'}>
        <FAB
          type="success"
          icon="MdAddLocationAlt"
          shape="circle"
          size="large"
          onClick={() => navigate(tenantRoutes.app('', false, userData).post_listing.path)}
        />
      </Tooltip>
      {isMobile ? (
        <MobileNavBar
          isMobile={isMobile}
          userData={userData}
          logoutUser={logoutUser}
          localeData={localeData}
          locale={locale}
          countryList={countryList}
          setShowLocaleModal={setShowLocaleModal}
          setNoFavPropModal={setNoFavPropModal}
          isFavpropLoading={isFavpropLoading}
          setNoSaveSearchModal={setNoSaveSearchModal}
          saveSettings={saveSettings}
          pathLocale={pathLocale}
          toggleLanguage={toggleLanguage}
          languageObj={languageObjectToSwitch}
          savedSearches={savedSearches}
          favouriteProperties={favouriteProperties}
        />
      ) : (
        <DeskTopNavBar
          userData={userData}
          logoutUser={logoutUser}
          setNewLocale={setNewLocale}
          locale={locale}
          setNoSaveSearchModal={setNoSaveSearchModal}
          savedSearches={savedSearches}
          favouriteProperties={favouriteProperties}
          setShowLocaleModal={setShowLocaleModal}
          showChangeCurrencyModal={showChangeCurrencyModal}
          setShowChangeCurrencyModal={setShowChangeCurrencyModal}
          showChangeAreaUnitModal={showChangeAreaUnitModal}
          setShowChangeAreaUnitModal={setShowChangeAreaUnitModal}
          setNoFavPropModal={setNoFavPropModal}
          isFavpropLoading={isFavpropLoading}
          saveSettings={saveSettings}
          pathLocale={pathLocale}
          toggleLanguage={toggleLanguage}
          languageObj={languageObjectToSwitch}
        />
      )}
    </>
  );
};
