import tenantFilters from '@filters';
import tenantTheme from '@theme';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Tabs } from '../../../../components/common';
import { ListingCardContainer } from '../../../../components/listing-card-container/ListingCardContainer';
import { ListingContainer } from '../../../../components/listing-container/ListingContainer';
import PostListingButton from '../../../../components/post-listing-button/post-listing-button';
import { EmptyListing } from '../../../../components/svg';
import { Main } from '../../../../container/styled';
import { useFetchOnQueryUpdate, useGetLocation, useRouteNavigate } from '../../../../hooks';
import { fetchListings, setListingId } from '../../../../redux/listings/actionCreator';
import { mapQueryStringToFilterObject } from '../../../../utility/urlQuery';
import { TabsStyled } from '../../bayut/listing/styled';
import ApplyBulkRefresh from './bulk-refresh';
import listingStates from './listings.json';

const Listings = () => {
  const { user } = useSelector((state) => state.loginUser);
  const users = useSelector((state) => state.userGroup.list);
  const dispatch = useDispatch();
  const listingsData = useSelector((state) => state.listings);
  const { selectedListings } = useSelector((state) => state.listings);
  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  const location = useGetLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { queryObj } = mapQueryStringToFilterObject(location.search);
  const filtersList = useMemo(() => tenantFilters.getMyListingsFilters(user, users), [user, users]);

  const { fetchData } = useFetchOnQueryUpdate(
    (params) => {
      if (user?.id) {
        dispatch(fetchListings(user.id, params, filtersList, isMobile ? '' : 'desktop'));
      }
    },
    [user?.id],
  );
  const [tab, setTab] = useState('');
  const navigate = useRouteNavigate();

  useEffect(() => {
    return () => {
      dispatch(setListingId({ data: [], selected: false, multiple: false, clearSelectedItems: true }));
    };
  }, []);

  useEffect(() => {
    const { queryObj } = mapQueryStringToFilterObject(location.search);
    listingsData?.tabFilterKey &&
      setTab(queryObj?.['q[firmstate]'] || queryObj?.[listingsData?.tabFilterKey] || listingsData?.statuses?.[0]?.key);
  }, [listingsData, location.search]);

  const renderStatesTabs = useCallback(() => {
    return (
      listingsData.statuses && (
        <TabsStyled
          className="mb-0"
          activeKey={tab || listingsData?.statuses?.[0]?.key?.toString()}
          onChange={onChangeTab}
        >
          {listingsData?.statuses?.map((e) => (
            <Tabs.TabPane tab={e?.tab} key={e?.key} />
          ))}
        </TabsStyled>
      )
    );
  }, [listingsData.statuses, tab]);

  const getRowSelection = () => {
    return {
      type: 'checkbox',
      preserveSelectedRowKeys: true,
      selectedRowKeys: selectedListings?.map((e) => e?.id),
      onSelect: (record, selected) => {
        const selectedListing = listingsData?.list?.find((e) => e?.id === record?.key);
        if (selected) {
          record.selected = true;
          record.listingOwner = selectedListing?.user;
        }
        dispatch(setListingId({ listings: { ...record, id: record?.key }, selected, multiple: false }));
      },
      onSelectAll: (selected, selectedRows, currentSelectedRows) => {
        let listings = [];
        currentSelectedRows?.forEach((e) => {
          e.selected = selected;
          e.listingOwner = listingsData?.list?.find((data) => data?.id == e?.key)?.user;
          listings.push({ ...e, id: e?.key });
        });
        dispatch(setListingId({ listings: listings, selected, multiple: true }));
      },
    };
  };

  const onChangeTab = (e) => {
    const obj = { ...queryObj, [listingsData?.tabFilterKey]: e };
    const decodedParams = Object.entries(obj).reduce((acc, [key, value]) => {
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {});
    setSearchParams(decodedParams);
  };
  const emptyState = useMemo(
    () => ({
      title: listingStates.listingStates[tab]?.title,
      subtitle: listingStates.listingStates[tab]?.subtitle,
      illustration: <EmptyListing color={tenantTheme['primary-light-2']} />,
      button: <PostListingButton />,
    }),
    [tab],
  );

  return (
    <>
      {isMobile ? (
        <Main style={{ paddingBlock: '0 8px' }}>
          <ListingCardContainer
            filtersList={filtersList}
            listingsData={listingsData}
            renderTableTopRight={renderStatesTabs}
            pagination={listingsData?.pagination}
            renderFiltersAsTopRight
            fetchData={fetchData}
            skeletonLoading={!listingsData?.list || !!listingsData.loading}
            showPagination
            loading={listingsData.loading}
            error={listingsData?.error}
            scrollToShowMore
            selectedListings={selectedListings}
            setSelectedListings={(listings) => dispatch(setListingId(listings))}
            emptyState={emptyState}
          />
        </Main>
      ) : (
        <>
          <ListingContainer
            filtersList={filtersList}
            listingsData={listingsData}
            listingApi={fetchListings}
            activeTab={tab}
            renderTableTopRight={listingsData.statuses}
            onChangeTab={onChangeTab}
            loading={listingsData.loading}
            error={listingsData.error}
            onRetry={fetchData}
            rowSelection={tab === 'Active' && listingsData?.list?.length ? getRowSelection() : undefined}
            emptyState={emptyState}
            disableTabsOnLoading
          />
        </>
      )}
      <ApplyBulkRefresh fetchData={fetchData} />
    </>
  );
};

export default Listings;
